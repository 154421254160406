import { State } from '@hookstate/core'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
import { useTour } from '@reactour/tour'
import { websiteStateObject } from 'lib/website-state'
import ThreeDIcon from '../icons/three-d-icon'
import SvgButtonRound from './svg-button-round'

export default function BackButton({
  className,
  active,
  websiteState,
}: {
  className?: string
  active?: boolean
  websiteState: State<typeof websiteStateObject, {}>
}) {
  const { threeDActive, bottomPanelIndex } = websiteState
  const { isOpen, setCurrentStep } = useTour()

  const onClick = () => {
    bottomPanelIndex.set(0)
  }

  return (
    <SvgButtonRound
      id={'back_button'}
      className={className}
      active={bottomPanelIndex.get() === 1}
      onClick={onClick}
      message={
        threeDActive.get()
          ? 'Surface structure mode activated'
          : '3D mode activated'
      }
    >
      <MdOutlineKeyboardBackspace className='w-full h-full' />
    </SvgButtonRound>
  )
}
