import { State } from '@hookstate/core'
import { AnimatePresence, motion } from 'framer-motion'
import { listOfPickerColors } from 'lib/constants'
import {
  animateStateMenuPopper,
  exitStateMenuPopper,
  initialStateMenuPopper,
  springMotion,
  springMotionMenuPopper,
  whileHover,
  whileTap,
} from 'lib/framer-motion-constants'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useElementSize } from 'usehooks-ts'
import SvgButtonRound from './svg-button-round'

function ColorDisplay({ color }: { color: string }) {
  return (
    <div
      className='w-full h-full rounded-[50%] p-1'
      style={{
        backgroundColor: `#${color}`,
      }}
    >
      <div className='w-full h-full rounded-[50%] border-2 border-primaryText-light' />
    </div>
  )
}

function ColorPickerButton({
  color,
  onClick,
}: {
  color: string
  onClick?: () => void
}) {
  return (
    //@ts-ignore
    <motion.button
      className='w-14 h-14 rounded-[50%] mb-1'
      onClick={onClick}
      whileTap={whileTap}
      whileHover={whileHover}
      transition={springMotion}
    >
      <ColorDisplay color={color} />
    </motion.button>
  )
}

export default function ColorButton({
  className,
  active,
  modelColor,
}: {
  className?: string
  active?: boolean
  modelColor: State<string, {}>
}) {
  const [colorPickerVisible, setColorPickerVisible] = useState(false)

  const [colorButtonRef, { width, height }] = useElementSize()

  const changeColor = (color: string) => {
    modelColor.set(color)
  }

  return (
    <div className={twMerge('relative flex', className)}>
      <AnimatePresence>
        {colorPickerVisible && (
          //@ts-ignore
          <motion.div
            ref={colorButtonRef}
            className='absolute origin-bottom z-10'
            style={{ top: -1 * height }}
            initial={initialStateMenuPopper}
            animate={animateStateMenuPopper}
            exit={exitStateMenuPopper}
            transition={springMotionMenuPopper}
          >
            {listOfPickerColors.map((color) => (
              <ColorPickerButton
                key={color}
                color={color}
                onClick={() => {
                  changeColor(color)
                  setColorPickerVisible(false)
                }}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      <SvgButtonRound
        id='color_button'
        className={`p-0 bg-transparent`}
        onClick={() => setColorPickerVisible((v) => !v)}
      >
        <ColorDisplay color={modelColor.get()} />
      </SvgButtonRound>
    </div>
  )
}
