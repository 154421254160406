import { State } from '@hookstate/core'
import * as THREE from 'three'
import { UClass, VClass } from './geometry-definitions'
import {
  CompleteObjectDefinition,
  ModelType,
  PartialObjectDefinition,
  modelSubTypes,
  modelTypes,
} from './mesh-maker'
import { stackerStateObject } from './stacker-state'
import { SurfaceStructureType } from './surface-structure-engine'

export enum lock_type {
  none,
  xLocked,
  yLocked,
  xyLocked,
}

export enum knot_type {
  catmull,
}

export enum deletable_type {
  yes,
  no,
}

export class Knot {
  position: { x: number; y: number }
  handleIn?: Knot
  handleOut?: Knot
  locked: lock_type
  type: knot_type
  deletable: deletable_type

  constructor(
    position: { x: number; y: number },
    locked: lock_type,
    deletable: deletable_type = deletable_type.yes,
    type: knot_type = knot_type.catmull
  ) {
    this.position = position
    this.locked = locked
    this.deletable = deletable
    this.type = type
  }

  static convertNormalizedPositionToEditorPosition(
    position: { x: number; y: number },
    printSpaceDimensions: { xy: number; z: number }
  ) {
    return {
      x: position.x * printSpaceDimensions.xy,
      y: position.y * printSpaceDimensions.z,
    }
  }

  static convertEditorPositionToNormalizedPosition(
    position: { x: number; y: number },
    printSpaceDimensions: { xy: number; z: number }
  ) {
    return {
      x: position.x / printSpaceDimensions.xy,
      y: position.y / printSpaceDimensions.z,
    }
  }

  getKnotWithUpdatedPosition(
    position: { x: number; y: number },
    printSpaceDimensions?: { xy: number; z: number }
  ) {
    if (printSpaceDimensions)
      position = Knot.convertEditorPositionToNormalizedPosition(
        position,
        printSpaceDimensions
      )

    let newPosition = this.position
    switch (this.locked) {
      case lock_type.none:
        newPosition = position
        break

      case lock_type.xLocked:
        newPosition.y = position.y
        break

      case lock_type.yLocked:
        newPosition.x = position.x
        break

      case lock_type.xyLocked:
        break

      default:
        newPosition = position
        break
    }

    return new Knot(newPosition, this.locked, this.deletable, this.type)
  }
}

export function getSplineFromKnots(
  knots: Knot[],
  printSpaceDimensions: { xy: number; z: number }
) {
  return new THREE.CatmullRomCurve3(
    knots.map((knot) => {
      const position = Knot.convertNormalizedPositionToEditorPosition(
        knot.position,
        printSpaceDimensions
      )

      return new THREE.Vector3(position.x, position.y, 0)
    })
  )
}

function getUDefinitionForStacker() {
  const curve = new THREE.EllipseCurve(0, 0, 1, 1)
  const definition = (u: number) => {
    const point = curve.getPointAt(u)
    return new THREE.Vector3(point.x, point.y, 0)
  }

  return new UClass(definition, { start: 0, end: 1 }, curve.getLength(), 0.01)
}

function getVDefintionForStacker(spline: THREE.Curve<THREE.Vector3>) {
  const definition = (v: number) => {
    return spline.getPointAt(v)
  }

  return new VClass(definition, { start: 0, end: 1 }, spline.getLength(), 0.01)
}

function getTransformationMatrix(modelType: ModelType) {
  const transformationMatrix = new THREE.Matrix4()

  const cos = Math.cos
  const sin = Math.sin
  const angle = modelType === modelTypes.lampBottom ? Math.PI / 2 : -Math.PI / 2

  //32px is the padding on the canvas so we need to move the model accordingly
  const yTranslation = modelType === modelTypes.lampBottom ? -32 : 32

  //prettier-ignore
  return transformationMatrix.set(
    1, 0, 0, 0,
    0, cos(angle), -sin(angle), 0,
    0, sin(angle), cos(angle), 0,
    0, 0, 0, 1
  )
}

export function createCompleteObjectFromStackerState(
  object: (typeof stackerStateObject.objects)[0],
  printSpaceDimensions: { xy: number; z: number },
  color: string,
  modelType: ModelType,
  surfaceStructure?: SurfaceStructureType
) {
  const spline = getSplineFromKnots(object.knots, printSpaceDimensions)
  const uObject = getUDefinitionForStacker()
  const vObject = getVDefintionForStacker(spline)

  const shell = new PartialObjectDefinition(
    modelSubTypes.shell,
    [
      { v: 0, uClass: uObject },
      { v: 1, uClass: uObject },
    ],
    vObject
  )

  const completeObject = new CompleteObjectDefinition(
    modelType,
    color,
    [shell],
    getTransformationMatrix(modelType),
    undefined,
    surfaceStructure
  )

  return completeObject
}

// position is in normalized coordinates
export function addKnot(
  object: State<(typeof stackerStateObject.objects)[0], {}>,
  position: { x: number; y: number },
  locked = lock_type.none,
  deletable = deletable_type.yes,
  type = knot_type.catmull
) {
  const curKnots = object.knots.get({ stealth: true })

  let insertionIndex = curKnots.findIndex(
    (knot) => knot.position.y > position.y
  )
  insertionIndex = insertionIndex < 0 ? curKnots.length : insertionIndex

  const newKnot = new Knot(position, locked, deletable, type)

  object.knots.set([
    ...curKnots.slice(0, insertionIndex),
    newKnot,
    ...curKnots.slice(insertionIndex),
  ])
}

export function removeKnot(
  object: State<(typeof stackerStateObject.objects)[0], {}>,
  index: number
) {
  if (
    index >= 0 &&
    index < object.knots.get({ stealth: true }).length &&
    object.knots[index].deletable.get({ stealth: true }) ===
      deletable_type.yes &&
    object.knots.get({ stealth: true }).length > 2 &&
    index < object.knots.get({ stealth: true }).length
  )
    object.knots.set((curValue) => {
      const newValue = [...curValue]
      newValue.splice(index, 1)
      return newValue
    })
}
