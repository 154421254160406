import { State, useHookstate } from '@hookstate/core'
import { CameraControls, Html } from '@react-three/drei'
import { useGesture } from '@use-gesture/react'
import { geometryState } from 'lib/geometry-engine/geometry-state'
import { ModelType, modelTypes } from 'lib/geometry-engine/mesh-maker'
import {
  Knot,
  addKnot,
  createCompleteObjectFromStackerState,
  getSplineFromKnots,
  lock_type,
  removeKnot,
} from 'lib/geometry-engine/stacker-engine'
import { stackerState } from 'lib/geometry-engine/stacker-state'
import { websiteState } from 'lib/website-state'
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import { Vector3 } from 'three'
import { useElementSize } from 'usehooks-ts'
import SquigglyButton from '../buttons/squiggly-button'
import SurfaceStructureButton from '../buttons/surface-structure-button'

function CameraUpdater({
  cameraControlsRef,
  viewportSize,
}: {
  cameraControlsRef: MutableRefObject<CameraControls | null>
  viewportSize: { height: number; width: number }
}) {
  const {
    threeDActive: threeDViewActive,
    zoomFactor,
    stacker: { currentObjectIndex },
    bottomPanelIndex,
  } = useHookstate(websiteState)

  useEffect(() => {
    if (!cameraControlsRef.current) return
    if (!threeDViewActive.get()) {
      const zoom = viewportSize.height / 350
      const position =
        currentObjectIndex.get() === 0
          ? [0, -150, 5.000000000000003]
          : [0, 150, 5.000000000000003]

      const target =
        currentObjectIndex.get() === 0
          ? [0, -150, 3.0736038735859635e-15]
          : [0, 150, 3.0736038735859635e-15]

      cameraControlsRef.current!.fromJSON(
        JSON.stringify({
          enabled: true,
          minDistance: 2.220446049250313e-16,
          maxDistance: 1.7976931348623157e308,
          minZoom: 0.01,
          maxZoom: 1.7976931348623157e308,
          minPolarAngle: 0,
          maxPolarAngle: 3.141592653589793,
          minAzimuthAngle: -1.7976931348623157e308,
          maxAzimuthAngle: 1.7976931348623157e308,
          smoothTime: 0.25,
          draggingSmoothTime: 0.125,
          dollySpeed: 1,
          truckSpeed: 30,
          dollyToCursor: false,
          verticalDragToForward: false,
          target: target,
          position: position,
          zoom: zoom,
          focalOffset: [0, 0, 0],
          target0: [0, 0, 0],
          position0: [0, 3.061616997868383e-16, 5],
          zoom0: 1.0108695652173914,
          focalOffset0: [0, 0, 0],
        }),
        true
      )
    } else if (bottomPanelIndex.get() === 0) {
      cameraControlsRef.current!.moveTo(1100, 200, 1100, true)
      cameraControlsRef.current!.setTarget(0, 0, 0, true)
      cameraControlsRef.current!.zoomTo(zoomFactor.get({ stealth: true }))
    } else if (bottomPanelIndex.get() === 1) {
      const zoom = viewportSize.height / 310
      const position =
        currentObjectIndex.get() === 0 ? [0, -150, 1000] : [0, 150, 1000]

      const target =
        currentObjectIndex.get() === 0
          ? [0, -150, 3.0736038735859635e-15]
          : [0, 150, 3.0736038735859635e-15]

      cameraControlsRef.current!.fromJSON(
        JSON.stringify({
          enabled: true,
          minDistance: 2.220446049250313e-16,
          maxDistance: 1.7976931348623157e308,
          minZoom: 0.01,
          maxZoom: 1.7976931348623157e308,
          minPolarAngle: 0,
          maxPolarAngle: 3.141592653589793,
          minAzimuthAngle: -1.7976931348623157e308,
          maxAzimuthAngle: 1.7976931348623157e308,
          smoothTime: 0.25,
          draggingSmoothTime: 0.125,
          dollySpeed: 1,
          truckSpeed: 30,
          dollyToCursor: false,
          verticalDragToForward: false,
          target: target,
          position: position,
          zoom: zoom,
          focalOffset: [0, 0, 0],
          target0: [0, 0, 0],
          position0: [0, 3.061616997868383e-16, 5],
          zoom0: 1.0108695652173914,
          focalOffset0: [0, 0, 0],
        }),
        true
      )
    }
  }, [threeDViewActive, bottomPanelIndex])

  useEffect(() => {
    cameraControlsRef.current!.zoomTo(zoomFactor.get({ stealth: true }))
  }, [zoomFactor])
  return <></>
}

export function DraggableKnot({
  parentHeight,
  parentWidth,
  onPositionUpdate,
  onMouseDown,
  onMouseUp,
  onContextMenu,
  active,
  selected,
  cameraControlsActive,
  threeDActive,
  setCameraControlsActive,
  index,
  flipped,
  locked = lock_type.none,
  controlledNormalizedPosition,
  className,
}: {
  parentWidth: number
  parentHeight: number
  locked?: lock_type
  onPositionUpdate?: (position: { x: number; y: number }) => void
  onMouseDown?: () => void
  onMouseUp?: () => void
  onContextMenu?: () => void
  active?: boolean
  selected?: boolean
  cameraControlsActive: boolean
  threeDActive: boolean
  setCameraControlsActive: Dispatch<SetStateAction<boolean>>
  index?: number
  flipped?: boolean
  controlledNormalizedPosition?: { x: number; y: number }
  className?: string
}) {
  const [normalizedPosition, setNormalizedPosition] = useState(
    controlledNormalizedPosition ? controlledNormalizedPosition : { x: 0, y: 0 }
  )

  if (!controlledNormalizedPosition)
    controlledNormalizedPosition = normalizedPosition

  useEffect(() => {
    if (onPositionUpdate) onPositionUpdate(normalizedPosition)
  }, [normalizedPosition])

  const [dotRadius, setDotRadius] = useState(parentWidth / 10)

  const { zoom } = useHookstate(websiteState)

  useEffect(() => {
    if (parentWidth > 0) setDotRadius(parentWidth / (4 * zoom.get()))
  }, [zoom, parentWidth])

  const gestures = useGesture(
    {
      onDrag: (state) => {
        const e = state.event
        if (onMouseDown) onMouseDown()

        //do nothing when the screen is being zoomed, panned or is in 3d view
        if (
          !(
            state.tap ||
            threeDActive ||
            cameraControlsActive ||
            state.touches > 1
          )
        ) {
          const target = (e.target as HTMLDivElement).parentElement!
          const bounds = target.getBoundingClientRect()

          const normalizedMousePosition = {
            x: (state.xy[0] - bounds.left) / bounds.width,
            y: flipped
              ? (bounds.bottom - state.xy[1]) / bounds.height
              : (state.xy[1] - bounds.top) / bounds.height,
          }

          if (normalizedMousePosition.x < 0) {
            normalizedMousePosition.x = 0
          } else if (normalizedMousePosition.x > 1)
            normalizedMousePosition.x = 1
          if (normalizedMousePosition.y < 0) {
            normalizedMousePosition.y = 0
          } else if (normalizedMousePosition.y > 1)
            normalizedMousePosition.y = 1

          setNormalizedPosition(normalizedMousePosition)
        }

        if (state.last || state.tap) {
          setCameraControlsActive(false)
          if (onMouseUp) onMouseUp()
        }
      },
    },

    {
      drag: { pointer: { buttons: [1] }, delay: 400 },
    }
  )

  const background = active
    ? 'bg-primaryText-light'
    : 'bg-primaryBackground-light'

  if (threeDActive) return <> </>
  return (
    <div
      className={twMerge(
        `z-20 border-2 border-primaryText-light rounded-full`,
        background,
        className
      )}
      style={{
        position: 'absolute',
        width: dotRadius + 'px',
        height: dotRadius + 'px',
        left: controlledNormalizedPosition.x * parentWidth,
        top: controlledNormalizedPosition.y * parentHeight,
        transform: 'translateY(-50%) translateX(-50%)',
      }}
      onContextMenu={onContextMenu}
      {...gestures()}
    ></div>
  )
}

function SplineVisualizer({
  knots,
  printSpaceDimensions,
}: {
  knots: Knot[]
  printSpaceDimensions: { xy: number; z: number }
}) {
  const spline = getSplineFromKnots(knots, printSpaceDimensions)

  const points = spline.getSpacedPoints(200)

  const createPolylineString = (points: Vector3[]) => {
    return points
      .reduce(
        (prevValue: string, currentValue: Vector3) =>
          prevValue + currentValue.x + ' ' + currentValue.y + ', ',
        ''
      )
      .slice(0, -2)
  }

  const polylineString = createPolylineString(points)

  return (
    <svg className='w-full h-full absolute pointer-events-none z-10 overflow-visible'>
      <polyline
        className='fill-none stroke-primaryText-light stroke-[2px]'
        points={polylineString}
      />
    </svg>
  )
}

function IndividualObjectEditor({
  cameraControlsActive,
  setCameraControlsActive,
  objectIndex,
  flipped = false,
  modelType,
}: {
  cameraControlsActive: boolean
  setCameraControlsActive: Dispatch<SetStateAction<boolean>>
  flipped?: boolean
  objectIndex: number
  modelType: ModelType
}) {
  const [splineAreaRef, { width: splineAreaWidth, height: splineAreaHeight }] =
    useElementSize()

  const { objects, modelColor: color } = useHookstate(stackerState)

  const geoState = useHookstate(geometryState)

  const {
    threeDActive,
    stacker: { currentObjectIndex, activeKnotIndex },
    underUpdate,
  } = useHookstate(websiteState)

  const object = objects[objectIndex]

  useEffect(() => {
    console.log('use called')
    const completeObject = createCompleteObjectFromStackerState(
      object.get() as any,
      { xy: splineAreaWidth, z: splineAreaHeight },
      color.get(),
      modelType,
      object.surfaceStructure.get()
    )

    geoState.objects[objectIndex].set(completeObject)
  }, [object, color, splineAreaWidth, splineAreaHeight])

  const position: [number, number, number] =
    objectIndex === 0
      ? [0, (-1 * objects[0].printSpaceDimensions.z.get()) / 2, 0]
      : [0, objects[1].printSpaceDimensions.z.get() / 2, 0]

  const addNewKnot = (event: React.MouseEvent) => {
    const bounds = event.currentTarget.getBoundingClientRect()
    const position = {
      x: event.clientX - bounds.left,
      y: flipped ? bounds.bottom - event.clientY : event.clientY - bounds.top,
    }

    const normalizedPosition = {
      x: position.x / bounds.width,
      y: position.y / bounds.height,
    }

    addKnot(object, normalizedPosition)
  }

  return (
    <Html
      transform
      position={position}
      prepend
      style={{
        width: object.printSpaceDimensions.xy.get() + 'px',
        height: object.printSpaceDimensions.z.get() + 'px',
        outline: threeDActive.get() ? 'unset' : '2px dashed',
        outlineColor: threeDActive.get() ? 'unset' : 'rgb(229,231,235)',
        transformOrigin: '50% 50%',
        transform: flipped ? 'ScaleY(-1)' : 'unset',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      className={
        threeDActive.get()
          ? ''
          : 'bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] bg-[size:11px_11px]'
      }
      distanceFactor={400}
      name='drawingBoard'
    >
      <div
        id='drawing_board'
        className='relative w-[50%] h-full z-0 flex items-center justify-start'
        ref={splineAreaRef}
        onClick={(e: React.MouseEvent) => {
          if (
            e.currentTarget === e.target &&
            !threeDActive.get({ stealth: true })
          )
            addNewKnot(e)
        }}
      >
        <div className='relative right-[2rem]'>
          {threeDActive.get() && (
            <SquigglyButton index={objectIndex} className='mr-3 ' />
          )}
          {threeDActive.get() && <SurfaceStructureButton index={objectIndex} />}
        </div>

        {object.knots.get().map((knot, index) => (
          <DraggableKnot
            flipped={flipped}
            key={index}
            active={
              currentObjectIndex.get() === objectIndex &&
              activeKnotIndex.get() === index
            }
            onMouseDown={() => {
              underUpdate.set(true)
              activeKnotIndex.set(index)
            }}
            onMouseUp={() => {
              underUpdate.set(false)
            }}
            onContextMenu={() => removeKnot(object, index)}
            parentHeight={splineAreaHeight}
            parentWidth={splineAreaWidth}
            threeDActive={threeDActive.get({ stealth: true })}
            cameraControlsActive={cameraControlsActive}
            setCameraControlsActive={setCameraControlsActive}
            controlledNormalizedPosition={knot.position}
            onPositionUpdate={(newNormalizedPosition) => {
              const newKnot = object.knots[index]
                .get()
                .getKnotWithUpdatedPosition(newNormalizedPosition)
              object.knots[index].set(newKnot)
              currentObjectIndex.set(objectIndex)
            }}
          />
        ))}

        {!threeDActive.get() && (
          <SplineVisualizer
            knots={object.knots.get() as Knot[]}
            printSpaceDimensions={{
              xy: splineAreaWidth,
              z: splineAreaHeight,
            }}
          />
        )}
      </div>
    </Html>
  )
}

export default function SplineEditor({
  cameraControlsActive,
  setCameraControlsActive,
  cameraControlsRef,
  viewportSize,
}: {
  cameraControlsActive: boolean
  setCameraControlsActive: Dispatch<SetStateAction<boolean>>
  cameraControlsRef: MutableRefObject<CameraControls | null>
  viewportSize: { height: number; width: number }
}) {
  const { zoomFactor, threeDActive } = useHookstate(websiteState)

  const geoState = useHookstate(geometryState)

  useEffect(() => {
    //400 was experimentally found to be the perfect size for 1 zoom factor
    zoomFactor.set(viewportSize.height / 600)
  }, [viewportSize])

  useEffect(() => {
    threeDActive.set(true)

    return () => {
      threeDActive.set(false)
    }
  }, [])

  return (
    <>
      <IndividualObjectEditor
        objectIndex={0}
        cameraControlsActive={cameraControlsActive}
        setCameraControlsActive={setCameraControlsActive}
        modelType={modelTypes.lampBottom}
      />
      <IndividualObjectEditor
        flipped
        objectIndex={1}
        cameraControlsActive={cameraControlsActive}
        setCameraControlsActive={setCameraControlsActive}
        modelType={modelTypes.lampTop}
      />

      <CameraUpdater
        cameraControlsRef={cameraControlsRef}
        viewportSize={viewportSize}
      />
    </>
  )
}
