import { useHookstate } from '@hookstate/core'
import { websiteState } from 'lib/website-state'
import { twMerge } from 'tailwind-merge'
import SurfaceStructureIcon from '../icons/surface-structure-icon'
import SvgButtonRound from './svg-button-round'

export default function SurfaceStructureButton({
  className,
  index,
}: {
  className?: string
  index: number
}) {
  const {
    stacker: { currentObjectIndex, activeKnotIndex },
    bottomPanelIndex,
  } = useHookstate(websiteState)
  const { threeDActive } = useHookstate(websiteState)

  const setIndex = () => {
    currentObjectIndex.set(index)
    bottomPanelIndex.set(1)
    threeDActive.set(true)
  }

  return (
    <SvgButtonRound
      id={'surface_strcture_button'}
      className={twMerge(
        'bg-secondaryText-light shadow-custom_sm w-8 h-8 p-2',
        className
      )}
      onClick={setIndex}
    >
      <SurfaceStructureIcon />
    </SvgButtonRound>
  )
}
