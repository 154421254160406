import ArIcon from 'components/icons/ar'
import SvgButtonTransparent from './svg-button-transparent'
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter'
import { State, useHookstate } from '@hookstate/core'
import {
  geometryState,
  geometryStateObject,
} from 'lib/geometry-engine/geometry-state'
import { websiteState, websiteStateObject } from 'lib/website-state'
import { waterdropStateObject } from 'lib/waterdrop-engine/waterdrop-state'
import { Scene } from 'three'

export default function ArButton({
  className,
  active,
  geometryState,
  waterdropState,
}: {
  className?: string
  active?: boolean
  websiteState: State<typeof websiteStateObject, {}>
  geometryState: State<typeof geometryStateObject, {}>
  waterdropState: State<typeof waterdropStateObject, {}>
}) {
  const exporter = new USDZExporter()

  const { objects } = geometryState

  const exportUSDZ = async () => {
    //TODO This will only work for one object. How to make it work for multiple?

    const exportScene = new Scene()
    objects.get().reduce((scene, object) => {
      return object.getARScene(undefined, undefined, scene)
    }, exportScene)

    const result = await exporter.parse(exportScene)
    const link = document.createElement('a')
    link.rel = 'ar'
    document.body.appendChild(link)

    const image = document.createElement('img')
    image.src = 'static/background.png'
    link.appendChild(image)

    link.href = URL.createObjectURL(
      new Blob([result], { type: 'application/octet-stream' })
    )
    link.download = 'experience.usdz'

    link.click()

    document.body.removeChild(link)
  }

  return (
    <SvgButtonTransparent
      id='ar_button'
      className={className}
      active={active}
      onClick={exportUSDZ}
    >
      <ArIcon />
    </SvgButtonTransparent>
  )
}
