import {
  AlphaFormat,
  BackSide,
  BufferGeometry,
  CatmullRomCurve3,
  DataTexture,
  DepthFormat,
  DoubleSide,
  Float32BufferAttribute,
  FloatType,
  FrontSide,
  Line3,
  LineCurve3,
  Matrix4,
  Mesh,
  MeshDepthMaterial,
  MeshStandardMaterial,
  PlaneGeometry,
  RedFormat,
  Scene,
  ShaderMaterial,
  Vector2,
  Vector3,
} from 'three'
import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader'
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js'
import { UClass, VClass } from './geometry-definitions'
import { SurfaceStructureType } from './surface-structure-engine'

export enum editorTypeFlags {
  isSplineBased,
  isIneBased,
  isSketchBased,
  shouldShowAdvancedEditor,
  shouldTaperModifier,
  shouldHaveSplineScaler,
  shouldHaveTaperScaler,
  showWidthInSplineScaler,
  showHeightInSplineScaler,
  isWaterdropBased,
  isStackerBased,
}

export enum modelSubTypes {
  socket,
  shell,
  boolAdd,
  boolSub,
}

export class EditorType {
  id: string
  name: string
  flags: editorTypeFlags[]

  constructor(id: string, name: string, flags: editorTypeFlags[]) {
    this.id = id
    this.name = name
    this.flags = flags
  }
}

export const editorTypes = {
  waterdrop: new EditorType('waterdrop', 'schultzschultz', [
    editorTypeFlags.isWaterdropBased,
  ]),
  stacker: new EditorType('stacker', 'Stacker', [
    editorTypeFlags.isStackerBased,
  ]),
}

export class ModelType {
  id: string
  displayName: string
  editorType: EditorType
  minHeight: number
  bottomHoleSize: number
  bottomMinWidth: number
  cameraPosition: { position: Vector3; zoom: number }
  defaultModelOrientationVector: Vector3

  constructor(
    id: string,
    displayName: string,
    editorType: EditorType,
    minHeight: number, //all length units are in mm
    bottomHoleSize: number,
    bottomMinWidth: number,
    cameraPosition: { position: Vector3; zoom: number },
    defaultModelOrientationVector: Vector3
  ) {
    this.id = id
    this.displayName = displayName
    this.editorType = editorType
    this.minHeight = minHeight
    this.bottomHoleSize = bottomHoleSize
    this.bottomMinWidth = bottomMinWidth
    this.cameraPosition = cameraPosition
    this.defaultModelOrientationVector = defaultModelOrientationVector
  }
}

export const modelTypes = {
  waterdrop: new ModelType(
    'waterdrop',
    'Waterdrop',
    editorTypes.waterdrop,
    100,
    0,
    0,
    { position: new Vector3(0, 0, 0), zoom: 1 },
    new Vector3(0, 0, 1)
  ),
  lampTop: new ModelType(
    'lampTop',
    'LampTop',
    editorTypes.stacker,
    100,
    40,
    10,
    { position: new Vector3(0, 0, 0), zoom: 1 },
    new Vector3(0, 1, 0)
  ),
  lampBottom: new ModelType(
    'lampBottom',
    'LampBottom',
    editorTypes.stacker,
    100,
    40,
    10,
    { position: new Vector3(0, 0, 0), zoom: 1 },
    new Vector3(0, -1, 0)
  ),
}
export class PartialObjectDefinition {
  type: modelSubTypes
  uDefinitions: { v: number; uClass: UClass }[]
  vDefinition: VClass
  transformation: Matrix4

  constructor(
    type: modelSubTypes,
    uDefinitions: { v: number; uClass: UClass }[],
    vDefinition: VClass,
    transformation = new Matrix4().identity()
  ) {
    this.type = type
    this.uDefinitions = uDefinitions
    this.vDefinition = vDefinition
    this.transformation = transformation
  }
}
export class CompleteObjectDefinition {
  modelType: ModelType
  subObjects: PartialObjectDefinition[]
  color: string
  transformation: Matrix4
  resolution: [number, number]
  surfaceStructure?: SurfaceStructureType

  constructor(
    modelType: ModelType,
    color: string,
    subObjects: PartialObjectDefinition[],
    transformation = new Matrix4().identity(),
    resolution: [number, number] = [0.01, 0.01],
    surfaceStructure?: SurfaceStructureType
  ) {
    this.modelType = modelType
    this.subObjects = subObjects
    this.color = color
    this.transformation = transformation
    this.resolution = resolution
    this.surfaceStructure = surfaceStructure
  }

  getPoint(u: number, v: number): Vector3 {
    const shell = this.subObjects.filter(
      (elem) => elem.type === modelSubTypes.shell
    )[0]

    const vPoint = shell.vDefinition.getPoint(v)

    const uDefinitions = shell.uDefinitions
    const line = new LineCurve3(
      uDefinitions[0].uClass.getPoint(u),
      uDefinitions[1].uClass.getPoint(u).setZ(vPoint.y)
    )

    const uPoint = line.getPointAt(v)

    return new Vector3(uPoint.x * vPoint.x, uPoint.y * vPoint.x, vPoint.y)
  }

  getNormalUnitVector(prevPoint: Vector3, point: Vector3, nextPoint: Vector3) {
    const parallelVector = nextPoint.clone().sub(prevPoint)
    const normalUnitVector = parallelVector
      .clone()
      .applyAxisAngle(new Vector3(0, 0, 1), -Math.PI / 2)
      .divideScalar(parallelVector.length())

    return normalUnitVector
  }

  getPointsLayerWise(uResolution: number, vResolution: number) {
    const points: Vector3[][] = []
    const uvs: Vector2[][] = []

    for (let v = 0; v < 1; v += vResolution) {
      points.push([])
      uvs.push([])
      const lastLayerPoints = points[points.length - 1]
      const lastLayerUvs = uvs[uvs.length - 1]

      for (let u = 0; u < 1; u += uResolution) {
        lastLayerPoints.push(this.getPoint(u, v))
        lastLayerUvs.push(new Vector2(u, v))
      }
      lastLayerPoints.push(this.getPoint(1, v))
      lastLayerUvs.push(new Vector2(1, v))
    }

    //This is just to ensure that both u = 1 and v = 1 are always included in the geometry
    points.push([])
    uvs.push([])
    const lastLayerPoints = points[points.length - 1]
    const lastLayerUvs = uvs[uvs.length - 1]
    for (let u = 0; u < 1; u += uResolution) {
      lastLayerPoints.push(this.getPoint(u, 1))
      lastLayerUvs.push(new Vector2(u, 1))
    }
    lastLayerPoints.push(this.getPoint(1, 1))
    lastLayerUvs.push(new Vector2(1, 1))

    return { points, uvs }
  }

  //TODO planar geometry has no uvs. Maybe that's best. Think later
  getPlanarGeometry(uResolution: number, vResolution: number) {
    const { points: layerWisePoints } = this.getPointsLayerWise(
      uResolution,
      vResolution
    )

    const vertices: number[] = []

    for (
      let loopIndex = 0;
      loopIndex < layerWisePoints.length - 1;
      loopIndex++
    ) {
      const nextLoopIndex = loopIndex + 1

      const loop1 = layerWisePoints[loopIndex]
      const loop2 = layerWisePoints[nextLoopIndex]

      for (let vertexIndex = 0; vertexIndex < loop1.length; vertexIndex++) {
        const nextVertexIndex =
          vertexIndex < loop1.length - 1 ? vertexIndex + 1 : 0

        const loop1CurrentVertex = loop1[vertexIndex]
        const loop1NextVertex = loop1[nextVertexIndex]
        const loop2CurrentVertex = loop2[vertexIndex]
        const loop2NextVertex = loop2[nextVertexIndex]

        const triangularFaces = this.make2TriangularFacesFrom4Points(
          loop1CurrentVertex,
          loop1NextVertex,
          loop2CurrentVertex,
          loop2NextVertex
        )

        vertices.push(...triangularFaces)
      }
    }

    const geometry = new BufferGeometry()

    geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3))
    geometry.computeVertexNormals()

    return geometry
  }

  make2TriangularFacesFrom4Points(
    point1: Vector3 | Vector2,
    point2: Vector3 | Vector2,
    point3: Vector3 | Vector2,
    point4: Vector3 | Vector2
  ) {
    const returnArray = []

    const deconstructVector = (vec: Vector3 | Vector2) => {
      if (vec instanceof Vector3) {
        return [vec.x, vec.y, vec.z]
      } else {
        return [vec.x, vec.y]
      }
    }

    returnArray.push(...deconstructVector(point2))
    returnArray.push(...deconstructVector(point3))
    returnArray.push(...deconstructVector(point1))

    returnArray.push(...deconstructVector(point4))
    returnArray.push(...deconstructVector(point3))
    returnArray.push(...deconstructVector(point2))

    return returnArray
  }

  getSolidGeometry(
    uResolution: number,
    vResolution: number,
    applySurfaceStructures: boolean
  ) {
    const { points: layerWisePoints, uvs: layerWiseUvs } =
      this.getPointsLayerWise(uResolution, vResolution)
    const vertices: number[] = []
    const uvs: number[] = []

    const applyModifier = (
      vertex: Vector3,
      index: number,
      array: Vector3[],
      layerIndex: number
    ) => {
      if (!this.surfaceStructure) return vertex

      const nextIndex = index < array.length - 1 ? index + 1 : 0
      const prevIndex = index > 0 ? index - 1 : array.length - 1

      const prevVertex = array[prevIndex]
      const nextVertex = array[nextIndex]

      const normalVector = this.getNormalUnitVector(
        prevVertex,
        vertex,
        nextVertex
      )

      return vertex
        .clone()
        .add(
          normalVector.multiplyScalar(
            this.surfaceStructure.amplitude *
              Math.sin(
                this.surfaceStructure.frequency *
                  layerWiseUvs[layerIndex][index].x *
                  Math.PI *
                  2.0
              )
          )
        )
    }

    for (
      let loopIndex = 0;
      loopIndex < layerWisePoints.length - 1;
      loopIndex++
    ) {
      const nextLoopIndex = loopIndex + 1

      const loop1Uvs = layerWiseUvs[loopIndex]
      const loop2Uvs = layerWiseUvs[nextLoopIndex]

      const loop1 = layerWisePoints[loopIndex].map((point, index, array) =>
        applyModifier(point, index, array, loopIndex)
      )
      const loop2 = layerWisePoints[nextLoopIndex].map((point, index, array) =>
        applyModifier(point, index, array, loopIndex)
      )

      const offsetFunction = (
        vertex: Vector3,
        index: number,
        array: Vector3[]
      ) => {
        const nextIndex = index < array.length - 1 ? index + 1 : 0
        const prevIndex = index > 0 ? index - 1 : array.length - 1

        const prevVertex = array[prevIndex]
        const nextVertex = array[nextIndex]

        const normalVector = this.getNormalUnitVector(
          prevVertex,
          vertex,
          nextVertex
        )

        const thickness = -2

        const thicknessVector = normalVector.clone().multiplyScalar(thickness)

        const offsetVertex = thicknessVector.clone().add(vertex)

        return offsetVertex
      }

      const loop1Offset = loop1.map(offsetFunction)
      const loop2Offset = loop2.map(offsetFunction)

      for (let vertexIndex = 0; vertexIndex < loop1.length; vertexIndex++) {
        const nextVertexIndex =
          vertexIndex < loop1.length - 1 ? vertexIndex + 1 : 0

        const loop1CurrentVertex = loop1[vertexIndex].clone()
        const loop1NextVertex = loop1[nextVertexIndex].clone()
        const loop2CurrentVertex = loop2[vertexIndex].clone()
        const loop2NextVertex = loop2[nextVertexIndex].clone()

        const loop1CurrentVertexUv = loop1Uvs[vertexIndex]
        const loop1NextVertexUv = loop1Uvs[nextVertexIndex]
        const loop2CurrentVertexUv = loop2Uvs[vertexIndex]
        const loop2NextVertexUv = loop2Uvs[nextVertexIndex]

        const loop1CurrentVertexOffset = loop1Offset[vertexIndex].clone()
        const loop1NextVertexOffset = loop1Offset[nextVertexIndex].clone()
        const loop2CurrentVertexOffset = loop2Offset[vertexIndex].clone()
        const loop2NextVertexOffset = loop2Offset[nextVertexIndex].clone()

        const insideFaces = this.make2TriangularFacesFrom4Points(
          loop2CurrentVertex,
          loop2NextVertex,
          loop1CurrentVertex,
          loop1NextVertex
        )

        const insideFacesUvs = this.make2TriangularFacesFrom4Points(
          loop2CurrentVertexUv,
          loop2NextVertexUv,
          loop1CurrentVertexUv,
          loop1NextVertexUv
        )

        vertices.push(...insideFaces)
        uvs.push(...insideFacesUvs)

        const outsideFaces = this.make2TriangularFacesFrom4Points(
          loop1CurrentVertexOffset,
          loop1NextVertexOffset,
          loop2CurrentVertexOffset,
          loop2NextVertexOffset
        )

        const outsideFacesUvs = this.make2TriangularFacesFrom4Points(
          loop1CurrentVertexUv,
          loop1NextVertexUv,
          loop2CurrentVertexUv,
          loop2NextVertexUv
        )

        vertices.push(...outsideFaces)
        uvs.push(...outsideFacesUvs)

        if (loopIndex === 0) {
          const bottomFaces = this.make2TriangularFacesFrom4Points(
            loop1CurrentVertex,
            loop1NextVertex,
            loop1CurrentVertexOffset,
            loop1NextVertexOffset
          )

          const bottomFacesUvs = this.make2TriangularFacesFrom4Points(
            loop1CurrentVertexUv,
            loop1NextVertexUv,
            loop1CurrentVertexUv,
            loop1NextVertexUv
          )

          vertices.push(...bottomFaces)
          uvs.push(...bottomFacesUvs)
        }
        if (loopIndex === layerWisePoints.length - 2) {
          const topFaces = this.make2TriangularFacesFrom4Points(
            loop2CurrentVertexOffset,
            loop2NextVertexOffset,
            loop2CurrentVertex,
            loop2NextVertex
          )

          const topFacesUvs = this.make2TriangularFacesFrom4Points(
            loop2CurrentVertexUv,
            loop2NextVertexUv,
            loop2CurrentVertexUv,
            loop2NextVertexUv
          )

          vertices.push(...topFaces)
          uvs.push(...topFacesUvs)
        }
      }
    }

    const geometry = new BufferGeometry()

    geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3))
    geometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2))
    geometry.computeVertexNormals()

    return geometry
  }

  generateBumpMap(resolution = 10) {
    const shell = this.subObjects.filter(
      (elem) => elem.type === modelSubTypes.shell
    )[0]

    const numberOfLayers = shell.vDefinition.length / 3

    if (resolution % 2 === 0) resolution += 1
    const halfWayIndex = (resolution - 1) / 2

    const darkLevelsInOneLayer = Array(resolution)
      .fill(0)
      .map((_, index) => {
        const distance = Math.abs(index - halfWayIndex) / 1.7
        return Math.floor((1 - distance / halfWayIndex) * 255)
      })

    const width = 1
    const height = resolution * numberOfLayers

    const size = width * height
    const data = new Uint8Array(4 * size)

    for (let i = 0; i < size; i++) {
      const ind = i % darkLevelsInOneLayer.length
      const stride = i * 1
      data[stride] = darkLevelsInOneLayer[ind]
    }

    const texture = new DataTexture(data, width, height, RedFormat)
    texture.needsUpdate = true

    return texture
  }

  getMesh(
    uResolution: number = this.resolution[0],
    vResolution: number = this.resolution[1],
    getPlanar = false,
    applySurfaceStructures = true
  ) {
    const solidGeometry = this.getSolidGeometry(
      uResolution,
      vResolution,
      applySurfaceStructures
    )

    const bumpMap = this.generateBumpMap(30)

    let newMaterial = new MeshStandardMaterial({
      color: parseInt(this.color, 16),
      shadowSide: DoubleSide,
      metalness: 0.2,
      roughness: 0.5,
      aoMap: bumpMap,
    })

    newMaterial.side = DoubleSide

    const objectMesh = new Mesh(solidGeometry, newMaterial)
    objectMesh.castShadow = true
    objectMesh.receiveShadow = true

    objectMesh.geometry.applyMatrix4(this.transformation)
    return objectMesh
  }

  getARScene(
    uResolution: number = this.resolution[0],
    vResolution: number = this.resolution[1],
    startingScene?: Scene
  ) {
    const exportMesh = this.getMesh(uResolution, vResolution)

    const scene = startingScene ? startingScene : new Scene()

    scene.add(exportMesh)

    scene.scale.set(0.001, 0.001, 0.001)
    scene.updateMatrixWorld(true)

    return scene
  }

  getPrice(uResolution: number, vResolution: number) {
    const { points: layerWisePoints } = this.getPointsLayerWise(
      uResolution,
      vResolution
    )
    const multiplier = 0.000545

    const exactPrice = layerWisePoints.reduce(
      (totalAcc, layer) => {
        const layerTotal = layer.reduce(
          (curLayerAcc, point) => {
            const distanceFromPrevPoint = point.distanceTo(
              curLayerAcc.lastPoint
            )
            const price = distanceFromPrevPoint * multiplier
            return { lastPoint: point, total: curLayerAcc.total + price }
          },
          { lastPoint: layer[0], total: 0 }
        )

        return {
          lastPoint: layer[0],
          total:
            totalAcc.total +
            layerTotal.total * layer[0].distanceTo(totalAcc.lastPoint),
        }
      },
      { lastPoint: layerWisePoints[0][0], total: 0 }
    )

    const minPrice = 149.99
    return Math.max(Math.ceil(exactPrice.total) - 0.01, minPrice)
  }
}

export function getShadowMaterials(darkness: number) {
  const depthMaterial = new MeshDepthMaterial()
  depthMaterial.side = DoubleSide
  depthMaterial.userData.darkness = { value: darkness }
  depthMaterial.onBeforeCompile = function (shader) {
    shader.uniforms.darkness = depthMaterial.userData.darkness
    shader.fragmentShader = /* glsl */ `
						uniform float darkness;
						${shader.fragmentShader.replace(
              'gl_FragColor = vec4( vec3( 1.0 - fragCoordZ ), opacity );',
              'gl_FragColor = vec4( vec3( 0.0 ), ( 1.0 - fragCoordZ ) * darkness );'
            )}
					`
  }

  depthMaterial.depthTest = false
  depthMaterial.depthWrite = false

  const horizontalBlurMaterial = new ShaderMaterial(HorizontalBlurShader)
  horizontalBlurMaterial.depthTest = false

  const verticalBlurMaterial = new ShaderMaterial(VerticalBlurShader)
  verticalBlurMaterial.depthTest = false

  return {
    depthMaterial: depthMaterial,
    horizontalBlurMaterial,
    verticalBlurMaterial,
  }
}
