export const surfaceStructureNames = {
  wavy: 'wavy',
  woven: 'woven',
}

export const wavySurfaceStructureInitial = {
  name: surfaceStructureNames.wavy,
  amplitude: 4.5,
  frequency: 10,
}

export const wovenSurfaceStructureInitial = {
  name: surfaceStructureNames.woven,
  amplitude: 4.5,
  frequency: 10,
}

export interface SurfaceStructureType {
  name: string
  amplitude: number
  frequency: number
}
