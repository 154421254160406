import { State } from '@hookstate/core'
import roboto from 'lib/roboto_light_regular'
import * as THREE from 'three'
import { geometryStateObject } from 'lib/geometry-engine/geometry-state'
import { CompleteObjectDefinition } from 'lib/geometry-engine/mesh-maker'
import {
  convertGridToShapes,
  convertShapeToUDefinition,
  functionCreateObjectDefinition,
  getLongestShape,
  getVDefinitionFromLength,
} from 'lib/waterdrop-engine/geomery-processor'
import { waterdropStateObject } from 'lib/waterdrop-engine/waterdrop-state'
import { websiteStateObject } from 'lib/website-state'
import { Text3D } from '@react-three/drei'

function createAndSetMeshObjects(
  shapes: THREE.Shape[],
  objects: State<CompleteObjectDefinition[], {}>,
  color: string,
  height: number,
  baseGridCellSize: number,
  waterdropGridSize: { x: number; y: number }
) {
  const longestShape = getLongestShape(shapes)

  if (longestShape) {
    const uObject = convertShapeToUDefinition(longestShape)
    const vObject = getVDefinitionFromLength(height)
    const completeObject = functionCreateObjectDefinition(
      uObject,
      vObject,
      color,
      baseGridCellSize,
      waterdropGridSize,
      height
    )

    objects.set([completeObject])
  } else {
    objects.set([])
  }
}

export default function IslandShapesAndMeasurementBoxes({
  waterdropState,
  geometryState,
  websiteState,
}: {
  waterdropState: State<typeof waterdropStateObject, {}>
  geometryState: State<typeof geometryStateObject, {}>
  websiteState: State<typeof websiteStateObject, {}>
}) {
  const {
    baseGrid,
    baseGridCellSize,
    waterdropGridSize,
    brushSizeRatio,
    minBrushSize,
    radiusRatio,
    height,
    modelColor,
  } = waterdropState
  const { objects } = geometryState
  const {
    threeDActive,
    underUpdate,
    showMeasurements,
    atLeastOneObjectExists,
  } = websiteState

  const brushSize = brushSizeRatio.get() * minBrushSize.get()

  const shapes = convertGridToShapes(
    //@ts-ignore
    baseGrid.get(),
    baseGridCellSize.get(),
    brushSize,
    radiusRatio.get()
  )

  if (shapes.length > 0) atLeastOneObjectExists.set(true)
  else atLeastOneObjectExists.set(false)

  if (!underUpdate.get({ stealth: true }))
    createAndSetMeshObjects(
      shapes,
      objects,
      modelColor.get(),
      height.get(),
      baseGridCellSize.get(),
      waterdropGridSize.get()
    )

  const xPosition = baseGridCellSize.get() / 2 - waterdropGridSize.get().x / 2
  const yPosition = baseGridCellSize.get() / 2 - waterdropGridSize.get().y / 2

  return (
    <>
      {!threeDActive.get() &&
        shapes.map((islandShape, index) => {
          const shapeGeometry = new THREE.ShapeGeometry(islandShape)

          const material = new THREE.MeshBasicMaterial({
            color: 0x000000,
          })

          material.side = THREE.BackSide
          const objectMesh = new THREE.Mesh(shapeGeometry, material)
          objectMesh.geometry.rotateX(Math.PI)
          objectMesh.geometry.translate(xPosition, -yPosition, 0)

          const box = new THREE.Box3()
          box.setFromObject(objectMesh)

          return (
            <group key={index}>
              <mesh
                //@ts-ignore
                geometry={shapeGeometry}
                key={index}
                material={material}
              />
              {!threeDActive.get() && showMeasurements.get() && (
                <box3Helper material={objectMesh.material} box={box} />
              )}
              {!threeDActive.get() &&
                showMeasurements.get() &&
                (['x', 'y'] as ('x' | 'y')[]).map((axis) => {
                  const size: number = box.max[axis] - box.min[axis]
                  let position = new THREE.Vector3(0, 0, 0)
                  const midPoint = box.min[axis] + size / 2
                  switch (axis) {
                    case 'x':
                      position = box.min.clone()
                      position.x = midPoint
                      position.y = box.max.y + 10
                      break
                    case 'y':
                      position = box.max.clone()
                      position.y = midPoint
                      position.x = position.x + 10
                      break
                  }
                  return (
                    <Text3D
                      key={axis}
                      font={roboto as any} //this was changed to any type because there seems to be a problem with ThreeJS' expected font
                      position={[position.x, position.y, position.z]}
                      size={10}
                    >
                      {size.toFixed(2) + 'mm'}
                      <meshLambertMaterial attach='material' color={'silver'} />
                    </Text3D>
                  )
                })}
            </group>
          )
        })}
    </>
  )
}
