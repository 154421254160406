import { useHookstate } from '@hookstate/core'
import { isTouchDevice } from 'lib/commons'
import { EditorType, editorTypes } from 'lib/geometry-engine/mesh-maker'
import { stackerState } from 'lib/geometry-engine/stacker-state'
import { waterdropState } from 'lib/waterdrop-engine/waterdrop-state'
import { websiteState } from 'lib/website-state'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import AddKnotButton from './buttons/add-knot-button'
import BrushSizeButton from './buttons/brush-size-button'
import BrushTypeButton from './buttons/brush-type-button'
import ColorButton from './buttons/color-button'
import HeightButton from './buttons/height-button'
import MagicButton from './buttons/magic-button'
import MeasurementsButton from './buttons/measurements-button'
import RemoveKnotButton from './buttons/remove-knot-button'
import WavySurfaceStructureButton from './buttons/surfaceStructureButtons/wavy-surface-structure-button'
import WovenSurfaceStructureButton from './buttons/surfaceStructureButtons/woven-surface-structure-button'
import ThreeDButton from './buttons/three-d-button'
import StepPicker from './step-picker'
import NoneSurfaceStructureButton from './buttons/surfaceStructureButtons/none-surface-structure-button'
import BackButton from './buttons/back-button'

function whichBottomPanelToShow(
  editorType: EditorType,
  bottomPanelIndex: number,
  threeDActive: boolean
) {
  if (editorType.id === editorTypes.waterdrop.id && !threeDActive) return 0
  if (editorType.id === editorTypes.waterdrop.id && threeDActive) return -1
  if (editorType.id === editorTypes.stacker.id && !threeDActive) return 1
  if (
    editorType.id === editorTypes.stacker.id &&
    threeDActive &&
    bottomPanelIndex === 1
  )
    return 2
  if (
    editorType.id === editorTypes.stacker.id &&
    threeDActive &&
    bottomPanelIndex === 0
  )
    return -2
}

export default function BottomDrawer({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}) {
  const webState = useHookstate(websiteState)
  const waterState = useHookstate(waterdropState)
  const stackState = useHookstate(stackerState)

  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])

  const bottomPanelIndex = whichBottomPanelToShow(
    webState.editorType.get() as any,
    webState.bottomPanelIndex.get(),
    webState.threeDActive.get()
  )

  return (
    <div
      id='bottom_drawer'
      className={twMerge(
        'relative w-[100%] flex flex-col items-center justify-center mb-4',
        className
      )}
    >
      {/* ********** Waterdrop Panels ************** */}
      {bottomPanelIndex === 0 && (
        <div className='flex mb-3 z-10'>
          <ThreeDButton websiteState={webState} className='mr-3' />
          <BrushTypeButton websiteState={webState} className='mr-3' />
          <BrushSizeButton waterdropState={waterState} className='mr-3' />
          <MagicButton websiteState={webState} />
        </div>
      )}

      {bottomPanelIndex === -1 && (
        <div className='flex mb-3 z-10'>
          <ThreeDButton websiteState={webState} className='mr-3' />
          <ColorButton modelColor={waterState.modelColor} className='mr-3' />
          <MeasurementsButton websiteState={webState} className='mr-3' />
          <HeightButton waterdropState={waterState} className='mr-3' />
        </div>
      )}

      {/* ********** Stacker Panels ************** */}
      {bottomPanelIndex === 1 && (
        <div className='flex mb-3 z-10'>
          <ThreeDButton websiteState={webState} className='mr-3' />
          <RemoveKnotButton
            websiteState={webState}
            stackerState={stackState}
            className='mr-3'
          />
          <AddKnotButton websiteState={webState} stackerState={stackState} />
        </div>
      )}

      {bottomPanelIndex === 2 && (
        <div className='flex mb-3 z-10'>
          <BackButton websiteState={webState} className='mr-3' />
          <NoneSurfaceStructureButton
            websiteState={webState}
            stackerState={stackState}
            className='mr-3'
          />
          <WavySurfaceStructureButton
            websiteState={webState}
            stackerState={stackState}
            className='mr-3'
          />
          {/* <WovenSurfaceStructureButton
            websiteState={webState}
            stackerState={stackState}
      /> */}
        </div>
      )}

      {bottomPanelIndex === -2 && (
        <div className='flex mb-3 z-10'>
          <ThreeDButton websiteState={webState} className='mr-3' />
          <ColorButton modelColor={stackerState.modelColor} className='mr-3' />
          <MeasurementsButton websiteState={webState} className='mr-3' />
        </div>
      )}
      <StepPicker className='mb-2' />
      <div className='text-secondaryBackground-light font-light text-sm'>
        {hasMounted && isTouchDevice()
          ? 'Swipe up for more'
          : 'Scroll here for more'}
      </div>
    </div>
  )
}
