import { State } from '@hookstate/core'
import { geometryStateObject } from './geometry-engine/geometry-state'
import { waterdropStateObject } from './waterdrop-engine/waterdrop-state'
import { v4 as uuidv4 } from 'uuid'
import { NextRouter } from 'next/router'
import { websiteStateObject } from './website-state'
import { ToastContainer, toast } from 'react-toastify'
import { stackerStateObject } from './geometry-engine/stacker-state'
import { Knot } from './geometry-engine/stacker-engine'
import { editorTypes } from './geometry-engine/mesh-maker'

type stateType =
  | typeof waterdropStateObject
  | typeof geometryStateObject
  | typeof stackerStateObject

const VERSION = '1.0'
export function serializeWaterdropState(
  version: string,
  uuid: string,
  waterdropStateToBeSerialized: typeof waterdropStateObject
) {
  const jsonObject = {
    _id: uuid,
    type: waterdropStateToBeSerialized.type,
    version: version,
    waterdropGridSize: waterdropStateToBeSerialized.waterdropGridSize,
    baseGridCellSize: waterdropStateToBeSerialized.baseGridCellSize,
    baseGrid: waterdropStateToBeSerialized.baseGrid,
    minBrushSize: waterdropStateToBeSerialized.minBrushSize,
    brushSizeRatio: waterdropStateToBeSerialized.brushSizeRatio,
    radiusRatio: waterdropStateToBeSerialized.radiusRatio,
    height: waterdropStateToBeSerialized.height,
    modelColor: waterdropStateToBeSerialized.modelColor,
  }

  return jsonObject
}

export function serializeStackerState(
  version: string,
  uuid: string,
  stackerToBeSerialized: typeof stackerStateObject
) {
  const jsonObject = {
    _id: uuid,
    type: stackerToBeSerialized.type,
    version: version,
    modelColor: stackerToBeSerialized.modelColor,
    objects: stackerStateObject.objects,
  }

  return jsonObject
}

export function serialize(stateToBeSerialized: stateType, uuid: string) {
  const version = VERSION
  if (stateToBeSerialized.type === 'waterdrop')
    return JSON.stringify(
      serializeWaterdropState(
        version,
        uuid,
        stateToBeSerialized as typeof waterdropStateObject
      )
    )
  else if (stateToBeSerialized.type === 'stacker') {
    return JSON.stringify(
      serializeStackerState(
        version,
        uuid,
        stateToBeSerialized as typeof stackerStateObject
      )
    )
  }
}

export function deserializeAndSetState(
  stateToBeDeserialized: string | Object,
  stackerState: State<typeof stackerStateObject, {}>,
  waterdropStateHook: State<typeof waterdropStateObject, {}>,
  websiteState: State<typeof websiteStateObject, {}>
) {
  let jsonObject: any = {}

  if (
    typeof stateToBeDeserialized === 'string' ||
    stateToBeDeserialized instanceof String
  )
    jsonObject = JSON.parse(stateToBeDeserialized as string)
  else jsonObject = stateToBeDeserialized as Object

  if (jsonObject.type === 'waterdrop') {
    waterdropDeserialize(jsonObject, waterdropStateHook)
    websiteState.editorType.set(editorTypes.waterdrop)
  } else if (jsonObject.type === 'stacker') {
    stackerDeserialize(jsonObject, stackerState)
    websiteState.editorType.set(editorTypes.stacker)
  }
}

/*
Functions below will be versioned deserialisation functions. The target will be not make any new schema that breaks the old one.  
*/

function waterdropDeserialize(
  jsonObject: any,
  waterdropStateHook: State<typeof waterdropStateObject, {}>
) {
  if (jsonObject.version === '1.0') {
    const stateObject = get_v1_0_waterdropDeserializedState(jsonObject)
    waterdropStateHook.set(stateObject)
  }
}

function stackerDeserialize(
  jsonObject: any,
  stackerStateHook: State<typeof stackerStateObject, {}>
) {
  if (jsonObject.version === '1.0') {
    const stateObject = get_v1_0_stackerDeserializedState(jsonObject)
    stackerStateHook.set(stateObject)
  }
}

export function get_v1_0_stackerDeserializedState(jsonObject: any) {
  const objects = jsonObject.objects.map((object: any) => {
    const knots = object.knots.map((knot: any) => {
      return new Knot(knot.position, knot.locked, knot.deletable, knot.type)
    })

    return {
      knots: knots,
      printSpaceDimensions: object.printSpaceDimensions,
      surfaceStructure: object.surfaceStructure,
    }
  })

  return {
    type: jsonObject.type,
    modelColor: jsonObject.modelColor,
    objects: objects,
  }
}

export function get_v1_0_waterdropDeserializedState(jsonObject: any) {
  return jsonObject
}

export async function saveToDatabase(
  stateToBeSaved: stateType,
  uuid = uuidv4()
) {
  try {
    var res: any = await fetch(window.location.origin + '/api/urls', {
      method: 'POST',
      body: serialize(stateToBeSaved, uuid),
    })

    console.log(res)
    console.log('url saved: ' + uuid)
  } catch (e) {
    console.log('error', e)
    res = { status: 500 }
    throw 'Design could not be saved to the database'
  }

  if (res.status !== 200) {
    console.log(res)
    throw 'Design could not be saved to the database'
  } else {
    return uuid
  }
}

export async function getDesignFromURLAndSetState(
  id: string,
  stackerState: State<typeof stackerStateObject>,
  waterdropState: State<typeof waterdropStateObject>,
  websiteState: State<typeof websiteStateObject>
) {
  const fetchPromise = fetch(window.location.origin + '/api/urls?id=' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  try {
    toast.promise(fetchPromise, {
      pending: 'Please wait while we load your design from the database',
      success: 'Design loaded',
      error:
        "We couldn't find your design. Please check your internet connection",
    })
    const res = await fetchPromise

    const allPosts = await res.json()

    if (allPosts.data.length !== 0) {
      deserializeAndSetState(
        allPosts.data[0],
        stackerState,
        waterdropState,
        websiteState
      )
      return true
    } else {
      toast.error(
        "We couldn't find your design. Please check your internet connection"
      )
      return false
    }
  } catch (e) {
    toast.error(
      "We couldn't find your design. Please check your internet connection"
    )
    return false
  }
}
