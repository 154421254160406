import { TourProvider, useTour } from '@reactour/tour'
import WarningPopup from 'components/warning-popup'
import BottomDrawer from 'components/bottom-drawer'
import CartSidebar from 'components/cart-sidebar'
import HybridViewer from 'components/hybrid-viewer'
import TopButtonDrawer from 'components/top-button-drawer'
import { geometryState } from 'lib/geometry-engine/geometry-state'
import {
  deserializeAndSetState,
  getDesignFromURLAndSetState,
} from 'lib/serialisation-core'
import { waterdropState } from 'lib/waterdrop-engine/waterdrop-state'
import { RefContext, websiteState } from 'lib/website-state'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import StateMachine from 'components/user-onboarding/state-machine'
import { useHookstate } from '@hookstate/core'
import InspirationSection from 'components/inspirations-section'
import { isTouchDevice } from 'lib/commons'
import Head from 'next/head'
import { stackerState } from 'lib/geometry-engine/stacker-state'
import SurfaceStructureSliders from 'components/surface-structure-sliders'

export default function Home() {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const router = useRouter()

  const processUrl = () => {
    if (router.query.id)
      getDesignFromURLAndSetState(
        router.query.id as string,
        stackerState,
        waterdropState,
        websiteState
      )
  }

  const {
    userOnboarding: { allowNavigation },
    isTouchEnabled,
  } = useHookstate(websiteState)

  useEffect(() => {
    processUrl()
    isTouchEnabled.set(isTouchDevice())
  }, [router.isReady])

  return (
    <TourProvider
      steps={[]}
      showPrevNextButtons={allowNavigation.get()}
      disableDotsNavigation={!allowNavigation.get()}
      disableKeyboardNavigation={!allowNavigation.get()}
      disableInteraction={true}
      onClickMask={undefined}
    >
      <StateMachine />
      {/*@ts-ignore*/}
      <RefContext.Provider value={{ canvasRef: canvasRef }}>
        <Head>
          <title>Aeditto Playground</title>
          <meta
            name='description'
            content='Make your own 3D printable designs'
          />
          <link rel='icon' href='/favicon/favicon.ico' />
        </Head>

        <WarningPopup />
        <div className='h-[100vh] w-screen'>
          <div className='flex relative h-screen w-screen bg-primaryBackground-light overflow-x-hidden overflow-y-scroll'>
            <ToastContainer
              position='top-center'
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme='light'
            />
            <div className='flex flex-col h-full w-full box-border '>
              <div className='py-8 h-screen box-border'>
                <TopButtonDrawer className='px-8' />
                <HybridViewer />
                <BottomDrawer />
                <InspirationSection />
              </div>
            </div>
            <CartSidebar />
          </div>
        </div>
      </RefContext.Provider>
    </TourProvider>
  )
}
