import { useHookstate } from '@hookstate/core'
import BottomButton from './step-button'
import { websiteState } from 'lib/website-state'
import { editorTypes } from 'lib/geometry-engine/mesh-maker'
import { stackerState } from 'lib/geometry-engine/stacker-state'
import { waterdropState } from 'lib/waterdrop-engine/waterdrop-state'

function getWebsiteStateForEditor() {}

export default function EditorTypeSwitcherButton() {
  const { editorType, warningPopup } = useHookstate(websiteState)
  const stackState = useHookstate(stackerState)
  const waterState = useHookstate(waterdropState)

  const switchEditorType = () => {
    warningPopup.set({
      show: true,
      message:
        'This operation will change the editor type and you will loose your progress on the current design',
      proceedFunction: () => {
        if (editorType.get().id === editorTypes.waterdrop.id) {
          editorType.set(editorTypes.stacker)
          waterState.set(waterState.get({ stealth: true }))
        } else {
          editorType.set(editorTypes.waterdrop)
          stackState.set(stackState.get({ stealth: true }))
        }
      },
    })
  }

  return (
    <BottomButton onClick={switchEditorType} black>
      {editorType.get().name}
    </BottomButton>
  )
}
