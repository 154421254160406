import React from 'react'

export default function SurfaceStructureIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={'100%'}
      height={'100%'}
      fill='none'
      viewBox='0 0 19 19'
    >
      <path
        fill='#1C1B1F'
        d='M1.4 18c-.317-.067-.612-.238-.887-.512-.276-.276-.446-.571-.513-.888L16.6 0c.35.083.65.254.9.512.25.259.425.555.525.888L1.4 18zM0 11.7V8.9L8.9 0h2.8L0 11.7zM0 4V2C0 1.45.196.98.588.587A1.926 1.926 0 012 0h2L0 4zm18 2.3v2.8l-.475.475a5.046 5.046 0 00-1.063-.387A7.517 7.517 0 0015.3 9L18 6.3zM6.3 18L9 15.3c.033.4.096.787.188 1.162.091.375.22.73.387 1.063L9.1 18H6.3zm7.7-2h-3v-2h3v-3h2v3h3v2h-3v3h-2v-3z'
      ></path>
    </svg>
  )
}
