import { hookstate } from '@hookstate/core'
import { Knot, deletable_type, lock_type } from './stacker-engine'
import { listOfPickerColors } from '../constants'
import { SurfaceStructureType } from './surface-structure-engine'

const printSpaceDimensions = { xy: 300, z: 300 }
export const initialObjects = [
  {
    knots: [
      new Knot({ x: 0.3, y: 0 }, lock_type.yLocked, deletable_type.no),
      new Knot({ x: 0.5, y: 0.5 }, lock_type.none),
      new Knot({ x: 0.3, y: 0.8 }, lock_type.none),
    ],
    printSpaceDimensions: printSpaceDimensions,
    surfaceStructure: undefined,
  },
  {
    knots: [
      new Knot({ x: 0.3, y: 0 }, lock_type.yLocked, deletable_type.no),
      new Knot({ x: 0.5, y: 0.5 }, lock_type.none),
      new Knot({ x: 0.3, y: 0.8 }, lock_type.none),
    ],
    printSpaceDimensions: printSpaceDimensions,
    surfaceStructure: undefined,
  },
]

export const stackerStateObject = {
  type: 'stacker',
  modelColor: listOfPickerColors[0],
  objects: [
    {
      knots: [
        new Knot({ x: 0.3, y: 0 }, lock_type.yLocked, deletable_type.no),
        new Knot({ x: 0.5, y: 0.5 }, lock_type.none),
        new Knot({ x: 0.3, y: 0.8 }, lock_type.none),
      ],
      printSpaceDimensions: printSpaceDimensions,
      surfaceStructure: undefined as SurfaceStructureType | undefined,
    },
    {
      knots: [
        new Knot({ x: 0.3, y: 0 }, lock_type.yLocked, deletable_type.no),
        new Knot({ x: 0.5, y: 0.5 }, lock_type.none),
        new Knot({ x: 0.3, y: 0.8 }, lock_type.none),
      ],
      printSpaceDimensions: printSpaceDimensions,
      surfaceStructure: undefined as SurfaceStructureType | undefined,
    },
  ],
}

export const stackerState = hookstate(stackerStateObject)
