import ArIcon from 'components/icons/ar'
import SvgButtonTransparent from './svg-button-transparent'
import ResetIcon from '../icons/reset'
import { useHookstate } from '@hookstate/core'
import { waterdropState } from 'lib/waterdrop-engine/waterdrop-state'
import { websiteState } from 'lib/website-state'
import { generateMatrix } from 'lib/waterdrop-engine/matrix-processor'
import { editorTypes } from 'lib/geometry-engine/mesh-maker'
import {
  initialObjects,
  stackerState,
  stackerStateObject,
} from 'lib/geometry-engine/stacker-state'

export default function ResetButton({
  className,
  active,
}: {
  className?: string
  active?: boolean
}) {
  const { baseGrid, baseGridCellSize, waterdropGridSize } =
    useHookstate(waterdropState)
  const { lastAddedPoint, editorType } = useHookstate(websiteState)
  const { objects: stackerObjects } = useHookstate(stackerState)

  const resetFunction = () => {
    if (editorType.get().id === editorTypes.waterdrop.id) {
      baseGrid.set(
        generateMatrix(waterdropGridSize.get(), baseGridCellSize.get())
      )
      lastAddedPoint.set(undefined)
    } else if (editorType.get().id === editorTypes.stacker.id) {
      stackerObjects.set(initialObjects)
    }
  }

  return (
    <SvgButtonTransparent
      id='reset_button'
      className={className}
      active={active}
      onClick={resetFunction}
    >
      <ResetIcon />
    </SvgButtonTransparent>
  )
}
