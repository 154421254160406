import { State, useHookstate } from '@hookstate/core'
import {
  CameraControls,
  ContactShadows,
  Environment,
  OrthographicCamera,
  SpotLight,
  Text3D,
  useDepthBuffer,
  useHelper,
} from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGesture } from '@use-gesture/react'
import { geometryState } from 'lib/geometry-engine/geometry-state'
import {
  CompleteObjectDefinition,
  editorTypes,
  getShadowMaterials,
} from 'lib/geometry-engine/mesh-maker'
import roboto from 'lib/roboto_light_regular'
import { getIslandsFromGrid } from 'lib/waterdrop-engine/geomery-processor'
import { gridDownSampler } from 'lib/waterdrop-engine/matrix-processor'
import { waterdropState } from 'lib/waterdrop-engine/waterdrop-state'
import { RefContext, websiteState } from 'lib/website-state'
import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as THREE from 'three'
import { useElementSize } from 'usehooks-ts'
import {
  mouseStateNoRotate,
  mouseStateRotate,
  touchStateNoRotate,
  touchStateRotate,
} from './camera-controls'
import SplineEditor from './viewer-components/spline-editor'
import WaterDropDrawingBoard, {
  getDrawingBoardGestures,
} from './viewer-components/waterdrop-drawing-board'
import IslandShapesAndMeasurementBoxes from './viewer-components/waterdrop-islands'
import SurfaceStructureSliders from './surface-structure-sliders'

function CameraUpdater({
  cameraControlsRef,
  threeDViewActive,
  zoomFactor,
}: {
  cameraControlsRef: MutableRefObject<CameraControls | null>
  threeDViewActive: boolean
  zoomFactor: State<number, {}>
}) {
  useEffect(() => {
    if (!cameraControlsRef.current) return
    if (!threeDViewActive) {
      cameraControlsRef.current!.reset(true)
    } else {
      cameraControlsRef.current!.saveState()
      cameraControlsRef.current!.moveTo(1100, 200, 1100, true)
      cameraControlsRef.current!.setTarget(0, 0, 0, true)
    }
  }, [threeDViewActive])

  useEffect(() => {
    cameraControlsRef.current!.zoomTo(zoomFactor.get({ stealth: true }))
  }, [zoomFactor])
  return <></>
}

function BaseGridDebug() {
  const { baseGrid, baseGridCellSize, waterdropGridSize } =
    useHookstate(waterdropState)

  return baseGrid.get().flatMap((row, jIndex) => {
    return row.flatMap((cell, iIndex) => {
      const xPosition =
        iIndex * baseGridCellSize.get() +
        baseGridCellSize.get() / 2 -
        waterdropGridSize.get().x / 2
      const yPosition =
        -1 *
        (jIndex * baseGridCellSize.get() +
          baseGridCellSize.get() / 2 -
          waterdropGridSize.get().y / 2)
      if (cell === 1)
        return (
          <mesh position={[xPosition, yPosition, 0]}>
            <planeGeometry
              args={[baseGridCellSize.get(), baseGridCellSize.get()]}
            ></planeGeometry>
            <meshStandardMaterial color={'black'} />
          </mesh>
        )
      else <></>
    })
  })
}

function DownSampledGridDebug() {
  const {
    baseGrid,
    baseGridCellSize,
    waterdropGridSize,
    brushSizeRatio,
    minBrushSize,
  } = useHookstate(waterdropState)

  const brushSize = brushSizeRatio.get() * minBrushSize.get()
  const downSampledGrid = gridDownSampler(
    //@ts-ignore
    baseGrid.get(),
    baseGridCellSize.get(),
    brushSize
  )

  return downSampledGrid.flatMap((row) =>
    row.flatMap((node) => {
      const xPosition = node.position.x - waterdropGridSize.get().x / 2
      const yPosition = -1 * (node.position.y - waterdropGridSize.get().y / 2)

      if (node.value === 1)
        return (
          <mesh position={[xPosition, yPosition, 0]}>
            <planeGeometry args={[brushSize, brushSize]}></planeGeometry>
            <meshStandardMaterial color={'red'} />
          </mesh>
        )
      else <></>
    })
  )
}

function IslandDebug() {
  const {
    baseGrid,
    baseGridCellSize,
    waterdropGridSize,
    brushSizeRatio,
    minBrushSize,
  } = useHookstate(waterdropState)

  const brushSize = brushSizeRatio.get() * minBrushSize.get()

  const islands = getIslandsFromGrid(
    //@ts-ignore
    baseGrid.get(),
    baseGridCellSize.get(),
    brushSize
  )
  console.log(islands)

  return (
    <group>
      {islands.flatMap((island) =>
        island.nodes.flatMap((node) => {
          const xPosition = node.position.x - waterdropGridSize.get().x / 2
          const yPosition =
            -1 * (node.position.y - waterdropGridSize.get().y / 2)

          if (node.value === 1)
            return (
              <mesh position={[xPosition, yPosition, 0]}>
                <planeGeometry args={[brushSize, brushSize]}></planeGeometry>
                <meshStandardMaterial color={'black'} />
              </mesh>
            )
          else <></>
        })
      )}
    </group>
  )
}

function ContactShadow({ box }: { box: THREE.Box3 }) {
  const shadowPlaneRef = useRef<THREE.Group>(null)
  const shadowCameraRef = useRef<THREE.OrthographicCamera>(null)
  const blurPlaneRef = useRef<THREE.Mesh>(null)

  const position: [number, number, number] = [
    (box.max.x + box.min.x) / 2,
    box.min.y - 5,
    (box.max.z + box.min.z) / 2,
  ]

  const maxX = box.max.x * 2 - position[0]
  const minX = box.min.x * 2 - position[0]
  const maxZ = box.max.z * 2 - position[2]
  const minZ = box.min.z * 2 - position[2]

  const planeGeometry = new THREE.PlaneGeometry(maxX - minX, maxZ - minZ)

  if (shadowPlaneRef.current) {
    shadowPlaneRef.current?.position.set(...position)
  }

  // useHelper(shadowCameraRef as any, THREE.CameraHelper)
  const { target, targetBlur } = (() => {
    const target = new THREE.WebGLRenderTarget(1024, 1024, {
      stencilBuffer: false,
    })

    const targetBlur = new THREE.WebGLRenderTarget(512, 512, {
      stencilBuffer: false,
    })

    target.samples = 8
    target.texture.generateMipmaps = false

    return { target, targetBlur }
  })()

  const { depthMaterial, horizontalBlurMaterial, verticalBlurMaterial } =
    getShadowMaterials(1)

  const blurShadow = (gl: THREE.WebGLRenderer, blurAmount: number) => {
    let blurPlane = blurPlaneRef.current!
    blurPlaneRef.current!.visible = true

    blurPlane.material = horizontalBlurMaterial
    ;(blurPlane.material as THREE.ShaderMaterial).uniforms.tDiffuse.value =
      target.texture

    horizontalBlurMaterial.uniforms.h.value = (blurAmount * 1) / 256

    gl.setRenderTarget(targetBlur)
    gl.render(blurPlane, shadowCameraRef.current!)

    blurPlane.material = verticalBlurMaterial
    ;(blurPlane.material as THREE.ShaderMaterial).uniforms.tDiffuse.value =
      targetBlur.texture
    verticalBlurMaterial.uniforms.v.value = (blurAmount * 1) / 256

    gl.setRenderTarget(target)
    gl.render(blurPlane, shadowCameraRef.current!)

    blurPlane.visible = false
  }

  useFrame(({ gl, scene }) => {
    scene.overrideMaterial = depthMaterial
    gl.setRenderTarget(target)
    gl.render(scene, shadowCameraRef.current!)
    scene.overrideMaterial = null

    blurShadow(gl, 4)
    blurShadow(gl, 0.4)
    gl.setRenderTarget(null)
  })

  return (
    <group>
      <OrthographicCamera
        ref={shadowCameraRef}
        position={[0, position[1], 0]}
        rotation={[Math.PI / 2, 0, 0]}
        near={2}
        far={30}
        left={minX}
        right={maxX}
        top={maxZ}
        bottom={minZ}
      />

      <mesh
        geometry={planeGeometry}
        position={position}
        rotation={[-Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial map={target.texture} transparent />
        {/* <meshStandardMaterial color={'hotpink'} /> */}
      </mesh>

      <mesh
        geometry={planeGeometry}
        position={[position[0], position[1] + 15, position[2]]}
        rotation={[Math.PI / 2, 0, 0]}
        ref={blurPlaneRef}
        visible={false}
      ></mesh>
    </group>
  )
}

function MeshObjectsAndMeasurementBox() {
  const { objects } = useHookstate(geometryState)

  const { threeDActive, showMeasurements, editorType } =
    useHookstate(websiteState)
  const { underUpdate } = useHookstate(websiteState)

  const [buttomPlane, setBottomPlane] = useState<THREE.Mesh | null>(null)

  return (
    <>
      {objects.get().map((elem, index) => {
        const resolution: [number, number] | undefined[] = underUpdate.get()
          ? [0.05, 0.05]
          : [undefined, undefined]

        const objectMesh = elem.getMesh(...resolution)
        const box = new THREE.Box3()
        box.setFromObject(objectMesh)

        return (
          <group key={index}>
            <mesh
              geometry={objectMesh.geometry}
              material={objectMesh.material}
              castShadow
              receiveShadow
            />
            {threeDActive.get() && showMeasurements.get() && (
              <box3Helper material={objectMesh.material} box={box} />
            )}
            {threeDActive.get() &&
              showMeasurements.get() &&
              (['x', 'y', 'z'] as ('x' | 'y' | 'z')[]).map((axis) => {
                const size: number = box.max[axis] - box.min[axis]
                let position = new THREE.Vector3(0, 0, 0)
                const midPoint = box.min[axis] + size / 2
                switch (axis) {
                  case 'x':
                    position = box.min.clone()
                    position.x = midPoint
                    position.y = box.max.y + 10
                    break
                  case 'y':
                    position = box.max.clone()
                    position.y = midPoint
                    position.x = position.x + 10
                    break
                  case 'z':
                    position = box.max.clone()
                    position.z = midPoint
                    position.x = position.x + 10
                    break
                }
                return (
                  <Text3D
                    key={axis}
                    font={roboto as any} //this was changed to any type because there seems to be a problem with ThreeJS' expected font
                    position={[position.x, position.y, position.z]}
                    size={10}
                  >
                    {size.toFixed(2) + 'mm'}
                    <meshLambertMaterial attach='material' color={'silver'} />
                  </Text3D>
                )
              })}
            {index === 0 && <ContactShadow box={box} />}
          </group>
        )
      })}
    </>
  )
}

//useful helper to debug light
export function LightWithHelper() {
  const light = useRef<THREE.SpotLight>(null)
  const shadowCameraRef = useRef<THREE.OrthographicCamera>(null)

  useHelper(shadowCameraRef as any, THREE.CameraHelper)
  useHelper(light as any, THREE.DirectionalLightHelper, 1, 'red')

  const depthBuffer = useDepthBuffer({ frames: 1 })
  return (
    <SpotLight
      position={[0, 1000, 500]}
      color={'#FF0000'}
      intensity={10}
      ref={light}
      castShadow
    >
      <orthographicCamera ref={shadowCameraRef} attach='shadow-camera' />
    </SpotLight>
  )
}

export default function HybridViewer() {
  const cameraControlsRef = useRef<CameraControls | null>(null)
  const editorRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null)

  const webState = useHookstate(websiteState)

  const { threeDActive, editorType, zoom, zoomFactor } = webState

  const waterState = useHookstate(waterdropState)
  const geoState = useHookstate(geometryState)

  const [cameraControlsActive, setCameraControlsActive] = useState(false)
  const [dollySpeed, setDollySpeed] = useState(1)
  const [hasMounted, setHasMounted] = useState(false)

  const lastMousePosition = useRef<{ x: number; y: number } | undefined>(
    undefined
  )

  const drawingBoardGestures = getDrawingBoardGestures({
    setCameraControlsActive,
    cameraControlsActive,
    lastMousePosition,
    waterdropState: waterState,
    websiteState: webState,
  })

  const canvasGestures = useGesture(
    {
      onPinch: (state) => {
        cameraControlsRef.current?.zoomTo(state.offset[0])
      },
    },
    {
      pinch: { preventDefault: true },
    }
  )

  const { canvasRef }: { canvasRef: MutableRefObject<HTMLCanvasElement> } =
    useContext(RefContext)

  const [divRef, divSize] = useElementSize()

  return (
    <div
      className='h-[calc(100%-9rem)] py-3 relative'
      id='canvas'
      {...canvasGestures()}
      ref={divRef}
    >
      <SurfaceStructureSliders />
      <Canvas
        gl={{ preserveDrawingBuffer: true }}
        ref={canvasRef}
        shadows
        orthographic
        color='black'
        style={{ zIndex: 0 }}
        onCreated={({ camera }) => {
          const newCamera = camera as THREE.OrthographicCamera
          newCamera.left = 200
          newCamera.right = -200
          newCamera.top = 200
          newCamera.bottom = -200
          newCamera.near = 1
          newCamera.far = 4000

          setHasMounted(true)
        }}
      >
        <Environment
          near={1}
          far={4000}
          resolution={256}
          files='/static/img/empty_warehouse_01_1k.hdr'
        />

        <CameraControls
          makeDefault
          ref={cameraControlsRef}
          mouseButtons={
            threeDActive.get() ? mouseStateRotate : mouseStateNoRotate
          }
          touches={threeDActive.get() ? touchStateRotate : touchStateNoRotate}
          truckSpeed={30}
          dollySpeed={dollySpeed}
          onChange={(e: any) => zoom.set(e.target.camera.zoom)}
        />
        <ambientLight intensity={1} />

        {editorType.get().id === editorTypes.waterdrop.id && (
          <WaterDropDrawingBoard
            waterdropState={waterState}
            websiteState={webState}
            drawingBoardGestures={drawingBoardGestures}
            editorRef={editorRef}
            cameraControlsRef={cameraControlsRef}
            viewportSize={divSize}
          />
        )}

        {editorType.get().id === editorTypes.stacker.id && (
          <SplineEditor
            cameraControlsActive={cameraControlsActive}
            setCameraControlsActive={setCameraControlsActive}
            cameraControlsRef={cameraControlsRef}
            viewportSize={divSize}
          />
        )}

        {editorType.get().id === editorTypes.waterdrop.id && (
          <IslandShapesAndMeasurementBoxes
            waterdropState={waterState}
            websiteState={webState}
            geometryState={geoState}
          />
        )}

        <MeshObjectsAndMeasurementBox />
        {/* <BaseGridDebug /> */}
        {/* <IslandDebug /> */}
        {/* <DownSampledGridDebug /> */}
      </Canvas>
    </div>
  )
}
