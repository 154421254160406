import { State } from '@hookstate/core'
import { websiteStateObject } from 'lib/website-state'
import { FaMinus } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import SvgButtonRound from './svg-button-round'
//@ts-ignore
import { deletable_type, removeKnot } from 'lib/geometry-engine/stacker-engine'
import { stackerStateObject } from 'lib/geometry-engine/stacker-state'
import { useState } from 'react'

export default function RemoveKnotButton({
  className,
  active,
  websiteState,
  stackerState,
}: {
  className?: string
  active?: boolean
  websiteState: State<typeof websiteStateObject, {}>
  stackerState: State<typeof stackerStateObject, {}>
}) {
  const {
    stacker: { currentObjectIndex, activeKnotIndex },
  } = websiteState
  const { objects } = stackerState

  const removeThisKnot = () => {
    removeKnot(
      objects[currentObjectIndex.get({ stealth: true })],
      activeKnotIndex.get({ stealth: true })
    )
  }

  return (
    <div className={twMerge('relative flex', className)}>
      <SvgButtonRound
        id='brush_type_button'
        className={`p-[0.1rem] border-2 bg-transparent border-primaryText-light`}
        onClick={removeThisKnot}
      >
        <div className='rounded-[50%] w-full h-full p-2 bg-primaryText-light'>
          <FaMinus className='w-full h-full text-secondaryText-light' />
        </div>
      </SvgButtonRound>
    </div>
  )
}
