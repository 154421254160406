import { State } from '@hookstate/core'
import { useTour } from '@reactour/tour'
import { websiteStateObject } from 'lib/website-state'
import { TiCancel } from 'react-icons/ti'
import SvgButtonRound from '../svg-button-round'
import { stackerStateObject } from 'lib/geometry-engine/stacker-state'

export default function NoneSurfaceStructureButton({
  className,
  active,
  websiteState,
  stackerState,
}: {
  className?: string
  active?: boolean
  websiteState: State<typeof websiteStateObject, {}>
  stackerState: State<typeof stackerStateObject, {}>
}) {
  const {
    threeDActive,
    stacker: { currentObjectIndex },
  } = websiteState
  const { objects } = stackerState

  const onClick = () => {
    objects[currentObjectIndex.get()].surfaceStructure.set(undefined)
  }

  return (
    <SvgButtonRound
      id={'none_surface_structure_button'}
      className={className}
      active={!objects[currentObjectIndex.get()].surfaceStructure.get()}
      onClick={onClick}
    >
      <TiCancel className='w-full h-full' />
    </SvgButtonRound>
  )
}
