import { twMerge } from 'tailwind-merge'
import BookmarkButton from './buttons/bookmark-button'
import QuestionButton from './buttons/question-button'
import ArButton from './buttons/ar-button'
import { useHookstate } from '@hookstate/core'
import { geometryState } from 'lib/geometry-engine/geometry-state'
import { websiteState } from 'lib/website-state'
import { waterdropState } from 'lib/waterdrop-engine/waterdrop-state'
import CartButton from './buttons/cart-button'
import ResetButton from './buttons/doover-button'
import { stackerState } from 'lib/geometry-engine/stacker-state'

export default function TopButtonDrawer({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}) {
  const geoState = useHookstate(geometryState)
  const webState = useHookstate(websiteState)
  const waterState = useHookstate(waterdropState)
  const stackState = useHookstate(stackerState)

  return (
    <div
      id='top_drawer'
      className={twMerge('w-[100%] flex justify-between', className)}
    >
      <div className='flex'>
        <QuestionButton websiteState={webState} className='mr-10' />
        {webState.threeDActive.get() && (
          <ArButton
            geometryState={geoState}
            websiteState={webState}
            waterdropState={waterState}
            className='mr-10'
          />
        )}
        <BookmarkButton
          className='mr-10'
          waterdropState={waterState}
          websiteState={webState}
          stackerState={stackState}
        />
        <CartButton websiteState={webState} className='mr-10' />
      </div>
      <div>
        <ResetButton />
      </div>
    </div>
  )
}
