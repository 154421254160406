import { MutableRefObject, useContext, useState } from 'react'
import BottomButton from './step-button'
import { CartElement, RefContext, websiteState } from 'lib/website-state'
import { useHookstate } from '@hookstate/core'
import { waterdropState } from 'lib/waterdrop-engine/waterdrop-state'
import { v4 as uuidv4 } from 'uuid'
import { saveToDatabase } from 'lib/serialisation-core'
import ThreeDots from '../three-dots'
import { toast } from 'react-toastify'
import { stackerState } from 'lib/geometry-engine/stacker-state'
import { editorTypes } from 'lib/geometry-engine/mesh-maker'

export default function AddToCartButton() {
  const { canvasRef }: { canvasRef: MutableRefObject<HTMLCanvasElement> } =
    useContext(RefContext)
  const [isLoading, setIsLoading] = useState(false)

  const { showCartSidebar, cartElements, atLeastOneObjectExists } =
    useHookstate(websiteState)
  const waterState = useHookstate(waterdropState)
  const stackState = useHookstate(stackerState)
  const { editorType } = useHookstate(websiteState)

  const addToCartFunction = async () => {
    setIsLoading(true)
    if (
      editorType.get().id !== editorTypes.waterdrop.id ||
      atLeastOneObjectExists.get()
    ) {
      const imgUrl = canvasRef
        .current!.toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')

      const concernedState =
        editorType.get().id === editorTypes.waterdrop.id
          ? waterState.get()
          : stackState.get()

      console.log('saving to database')
      const savingPromise = saveToDatabase(concernedState as any)
      toast.promise(savingPromise, {
        pending: 'Saving design to database',
        success: 'Design Saved',
        error:
          "We couldn't save the design. Please check your internet connection",
      })

      const uuid = await savingPromise
      const name = editorType.get().name

      const fetchPromise = fetch(
        window.location.origin + '/api/get_price?id=' + uuid,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      toast.promise(fetchPromise, {
        pending: 'Calculating Price',
        success: 'Price Calculated',
        error: "Couldn't get price",
      })
      let result = await fetchPromise

      const json = await result.json()
      const price = json.data
      const quantity = 1

      const cartElement: CartElement = {
        imageURL: imgUrl,
        uuid: uuid,
        name: name,
        price: price,
        quantity: quantity,
      }

      cartElements.set((curCart) => [...curCart, cartElement])

      showCartSidebar.set(true)
    }
    setIsLoading(false)
  }

  return (
    <BottomButton
      id='add_to_cart_button'
      onClick={!isLoading ? addToCartFunction : () => {}}
    >
      {!isLoading ? 'Add to Cart' : 'Adding...'}
    </BottomButton>
  )
}
