import { twMerge } from 'tailwind-merge'
import { motion } from 'framer-motion'
import {
  animateStateOpacityRotation,
  animateStateOpacityScale,
  exitStateOpacityRotation,
  exitStateOpacityScale,
  initialStateOpacityRotation,
  initialStateOpacityScale,
  springMotion,
  springMotionLowStiffness,
  whileHover,
  whileTap,
} from 'lib/framer-motion-constants'

export default function BottomButton({
  id,
  className,
  children,
  black,
  onClick,
}: {
  id?: string
  className?: string
  children?: React.ReactNode
  black?: boolean
  onClick?: () => void
}) {
  const bgColor = black
    ? 'bg-primaryText-light text-primaryBackground-light'
    : 'bg-secondaryBackground-light'

  return (
    //@ts-ignore
    <motion.button
      id={id}
      className={twMerge(
        'flex justify-center px-4 min-w-[8rem]',
        bgColor,
        className
      )}
      onClick={onClick}
      initial={initialStateOpacityScale}
      animate={animateStateOpacityScale}
      exit={exitStateOpacityScale}
      whileHover={whileHover}
      whileTap={whileTap}
      transition={springMotionLowStiffness}
    >
      {children}
    </motion.button>
  )
}
