import { useHookstate } from '@hookstate/core'
import { editorTypes } from 'lib/geometry-engine/mesh-maker'
import { stackerState } from 'lib/geometry-engine/stacker-state'
import { websiteState } from 'lib/website-state'
import RangeSlider from './range-slider'
import { SurfaceStructureType } from 'lib/geometry-engine/surface-structure-engine'
import { useEffect } from 'react'

export default function SurfaceStructureSliders() {
  const {
    bottomPanelIndex,
    editorType,
    stacker: { currentObjectIndex },
    sliderActive,
    underUpdate,
  } = useHookstate(websiteState)
  const { objects } = useHookstate(stackerState)

  const showCondition =
    objects[currentObjectIndex.get()].surfaceStructure.get() &&
    bottomPanelIndex.get() === 1 &&
    editorType.get() === editorTypes.stacker

  const onAmplitudeChange = (value: number) => {
    objects[currentObjectIndex.get()].surfaceStructure.set((curVal) => {
      const obj = { ...curVal } as SurfaceStructureType
      obj.amplitude = value
      return obj
    })
  }

  const onFrequencyChange = (value: number) => {
    objects[currentObjectIndex.get()].surfaceStructure.set((curVal) => {
      const obj = { ...curVal } as SurfaceStructureType
      obj.frequency = value
      return obj
    })
  }

  return showCondition ? (
    <div className='absolute flex h-[35vh] right-4 md:right-[30%] bottom-6 z-40'>
      <div className='h-full mr-3'>
        <div className='flex justify-center text-secondaryBackground-light font-light '>
          Amplitude
        </div>

        <RangeSlider
          min={1}
          max={25}
          step={0.2}
          value={
            objects[currentObjectIndex.get()].surfaceStructure.get()!.amplitude
          }
          setValue={onAmplitudeChange}
        />
      </div>

      <div className='h-full'>
        <div className='flex justify-center text-secondaryBackground-light font-light '>
          Frequency
        </div>

        <RangeSlider
          min={5}
          max={20}
          step={1}
          value={
            objects[currentObjectIndex.get()].surfaceStructure.get()!.frequency
          }
          setValue={onFrequencyChange}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}
