import { Vector3 } from 'three'
import { CyclicNormalizedValue } from '../utils'

class parametricCurve {
  definition: (u: number) => Vector3
  range: { start: number; end: number }
  cyclical: boolean
  length: number
  discretisationInterval?: number

  constructor(
    definition: (u: number) => Vector3,
    range = { start: 0, end: 1 },
    cyclical = true,
    length: number,
    discretisationInterval?: number
  ) {
    this.definition = definition
    this.range = range
    this.cyclical = cyclical
    this.discretisationInterval = discretisationInterval
    this.length = length
  }

  getPoint(u: number) {
    let uValue = 0
    if (this.cyclical) {
      const cyclicU = new CyclicNormalizedValue(u, this.range)
      uValue = cyclicU.value
    } else {
      uValue = u
    }

    if (uValue < this.range.start || uValue > this.range.end)
      throw 'u value is out of range'

    return this.definition(uValue)
  }
}

export class UClass extends parametricCurve {
  constructor(
    definition: (u: number) => Vector3,
    range = { start: 0, end: 1 },
    length: number,
    discretisationInterval?: number
  ) {
    super(definition, range, true, length, discretisationInterval)
  }
}

export class VClass extends parametricCurve {
  constructor(
    definition: (u: number) => Vector3,
    range = { start: 0, end: 1 },
    length: number,
    discretisationInterval?: number
  ) {
    super(definition, range, false, length, discretisationInterval)
  }
}
