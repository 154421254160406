import { State } from '@hookstate/core'
import { websiteStateObject } from 'lib/website-state'
import { FaPlus } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import SvgButtonRound from './svg-button-round'
//@ts-ignore
import {
  Knot,
  addKnot,
  deletable_type,
  lock_type,
} from 'lib/geometry-engine/stacker-engine'
import { stackerStateObject } from 'lib/geometry-engine/stacker-state'

export default function AddKnotButton({
  className,
  active,
  websiteState,
  stackerState,
}: {
  className?: string
  active?: boolean
  websiteState: State<typeof websiteStateObject, {}>
  stackerState: State<typeof stackerStateObject, {}>
}) {
  const {
    stacker: { currentObjectIndex },
  } = websiteState
  const { objects } = stackerState

  const addNewNewKnot = () => {
    const knots = objects[currentObjectIndex.get({ stealth: true })].knots.get({
      stealth: true,
    })
    const lastKnot = knots[knots.length - 1]

    const newKnotPosition = {
      x: lastKnot.position.x,
      y: Math.min(1, lastKnot.position.y + 0.1),
    }

    addKnot(objects[currentObjectIndex.get({ stealth: true })], newKnotPosition)
  }

  return (
    <div className={twMerge('relative flex', className)}>
      <SvgButtonRound
        id='brush_type_button'
        className={`p-[0.1rem] border-2 bg-transparent border-primaryText-light`}
        onClick={addNewNewKnot}
        message={'Added another point '}
      >
        <div className='rounded-[50%] w-full h-full p-2 bg-primaryText-light'>
          <FaPlus className='w-full h-full text-secondaryText-light' />
        </div>
      </SvgButtonRound>
    </div>
  )
}
