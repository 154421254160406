import { State, useHookstate } from '@hookstate/core'
import { useTour } from '@reactour/tour'
import { websiteState, websiteStateObject } from 'lib/website-state'
import ThreeDIcon from '../icons/three-d-icon'
import SvgButtonRound from './svg-button-round'
import SquigglyLine from '../icons/squiggly-line'
import { stackerState } from 'lib/geometry-engine/stacker-state'
import { twMerge } from 'tailwind-merge'

export default function SquigglyButton({
  className,
  index,
}: {
  className?: string
  index: number
}) {
  const {
    stacker: { currentObjectIndex, activeKnotIndex },
    bottomPanelIndex,
  } = useHookstate(websiteState)
  const { threeDActive } = useHookstate(websiteState)

  const setIndex = () => {
    currentObjectIndex.set(index)
    bottomPanelIndex.set(0)
    threeDActive.set(false)
  }

  return (
    <SvgButtonRound
      id={'squiggly_button'}
      className={twMerge(
        'bg-secondaryText-light shadow-custom_sm w-8 h-8 p-2',
        className
      )}
      onClick={setIndex}
    >
      <SquigglyLine />
    </SvgButtonRound>
  )
}
