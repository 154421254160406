import { useHookstate } from '@hookstate/core'
import { websiteState } from 'lib/website-state'
import { AnimatePresence, motion } from 'framer-motion'
import {
  animateStateOpacityScale,
  exitStateOpacityScale,
  initialStateOpacityScale,
  springMotionLowStiffness,
} from 'lib/framer-motion-constants'
import BottomButton from './buttons/step-button'

export default function WarningPopup({}: {}) {
  const { warningPopup } = useHookstate(websiteState)

  const cancelOperation = () => {
    warningPopup.show.set(false)
  }

  const proceedOperation = () => {
    warningPopup.get({ noproxy: true }).proceedFunction()
    warningPopup.show.set(false)
  }

  return (
    <AnimatePresence>
      {warningPopup.show.get() && (
        //@ts-ignore
        <motion.div
          className={`absolute top-0 left-0 
    w-screen h-screen 
    z-30 
    flex 
    items-center justify-center`}
          initial={{ backgroundColor: 'rgba(0,0,0,0)' }}
          animate={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
          exit={{ backgroundColor: 'rgba(0,0,0,0)' }}
          transition={springMotionLowStiffness}
        >
          {/*@ts-ignore*/}
          <motion.div
            className='bg-primaryBackground-light p-4 rounded-lg opacity-100 shadow-custom_sm max-w-[80vw] lg:max-w-[35vw]'
            initial={initialStateOpacityScale}
            animate={animateStateOpacityScale}
            exit={exitStateOpacityScale}
            transition={springMotionLowStiffness}
          >
            <div className='mb-4'>{warningPopup.message.get()}</div>

            <div className='flex flex-col lg:flex-row'>
              <BottomButton
                className='mb-4 mr-0  lg:mb-0 lg:mr-4'
                onClick={cancelOperation}
              >
                Go Back
              </BottomButton>
              <BottomButton black onClick={proceedOperation}>
                Proceed
              </BottomButton>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
