import React from 'react'

export default function SguigglyLine() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 18 18'
    >
      <path
        fill='#1C1B1F'
        d='M10.889 17.55c-.82 0-1.481-.262-1.984-.787-.503-.525-.755-1.19-.755-1.997 0-.577.134-1.14.402-1.687a4.969 4.969 0 011.132-1.487 6.354 6.354 0 011.732-1.11 6.985 6.985 0 012.205-.553c-.075-.948-.272-1.64-.59-2.076-.318-.435-.793-.653-1.423-.653-.808 0-1.555.311-2.24.933a21.887 21.887 0 00-2.076 2.185c-.8.973-1.526 1.763-2.18 2.37-.653.608-1.387.912-2.202.912-.722 0-1.304-.254-1.747-.764-.442-.51-.663-1.18-.663-2.013 0-.934.218-1.842.655-2.725.436-.882.967-1.761 1.593-2.638.364-.523.67-1.005.918-1.446.248-.441.372-.837.372-1.187 0-.245-.056-.419-.168-.522-.112-.103-.267-.155-.466-.155-.23 0-.48.082-.75.246-.27.163-.57.434-.904.812L.692 2.15c.457-.509.912-.886 1.366-1.131C2.512.773 2.96.65 3.404.65c.63 0 1.142.204 1.54.611.396.407.594.925.594 1.555 0 .56-.16 1.144-.48 1.75a18.48 18.48 0 01-1.183 1.919c-.518.746-.96 1.474-1.326 2.183C2.183 9.377 2 10.082 2 10.783c0 .437.078.766.234.987.155.22.38.33.676.33.456 0 .921-.234 1.395-.704s1.06-1.12 1.758-1.951c.82-.981 1.667-1.85 2.543-2.608.875-.758 1.876-1.137 3.002-1.137 1.078 0 1.919.38 2.522 1.14.603.761.933 1.78.991 3.06H17.5v1.5h-2.379c-.1 1.788-.518 3.26-1.254 4.416-.736 1.156-1.729 1.734-2.979 1.734zm.011-1.5c.778 0 1.41-.438 1.895-1.314.485-.876.759-1.975.82-3.295-1.112.105-2.052.49-2.817 1.155-.765.664-1.148 1.38-1.148 2.147 0 .412.115.734.344.963.23.23.532.344.906.344z'
      ></path>
    </svg>
  )
}
