import { hookstate } from '@hookstate/core'
import { editorTypes } from 'lib/geometry-engine/mesh-maker'
import React from 'react'

export enum waterdrop_brushType {
  add,
  sub,
}

export interface CartElement {
  imageURL: string
  uuid: string
  name: string
  price: number
  quantity: number
}

export const websiteStateObject = {
  threeDActive: false,
  sliderActive: false,
  hasMounted: false,
  brushType: waterdrop_brushType.add,
  stacker: { activeKnotIndex: -1, currentObjectIndex: 0 },
  editorType: editorTypes.waterdrop,
  lastAddedPoint: undefined,
  bottomPanelIndex: 0,
  cartElements: [] as CartElement[],
  warningPopup: {
    show: false,
    message: '',
    proceedFunction: () => {
      console.log('hello')
    },
  },
  showCartSidebar: false,
  atLeastOneObjectExists: false,
  runTutorial: false,
  tutorialStepIndex: 0,
  showMeasurements: true,
  userOnboarding: {
    allowNavigation: true,
  },
  isTouchEnabled: false,
  currentInspirationsPage: 0,
  underUpdate: false,
  zoom: 1,
  zoomFactor: 1,
}

export const websiteState = hookstate(websiteStateObject)

export const RefContext = React.createContext<any>(null)
