import React from 'react'

export default function WavySurfaceStructureIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={'100%'}
      height={'100%'}
      fill='none'
      viewBox='0 0 22 22'
    >
      <path
        fill='#fff'
        d='M2.442 21.868c-.37-.078-.715-.277-1.036-.598-.32-.32-.52-.665-.597-1.035L20.17.873c.408.097.758.297 1.05.598.291.301.495.646.612 1.035L2.442 21.868zM.809 14.52v-3.266L11.189.873h3.266L.81 14.52zm0-8.981V3.206c0-.642.228-1.19.685-1.648A2.246 2.246 0 013.14.873h2.333L.81 5.539zm16.33 16.33l4.665-4.666v2.332c0 .642-.229 1.191-.686 1.648a2.246 2.246 0 01-1.647.685h-2.333zm-8.982 0L21.804 8.22v3.266L11.423 21.868H8.157z'
      ></path>
    </svg>
  )
}
