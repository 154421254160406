import { State, useHookstate } from '@hookstate/core'
import { useGesture } from '@use-gesture/react'
import * as THREE from 'three'
import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react'
import { waterdropStateObject } from 'lib/waterdrop-engine/waterdrop-state'
import {
  waterdrop_brushType,
  websiteState,
  websiteStateObject,
} from 'lib/website-state'
import {
  absolutePointToCellIndex,
  getListOfAffectedIndexes,
} from 'lib/waterdrop-engine/matrix-processor'
import { CameraControls, Html } from '@react-three/drei'

function CameraUpdater({
  cameraControlsRef,
}: {
  cameraControlsRef: MutableRefObject<CameraControls | null>
}) {
  const { threeDActive: threeDViewActive, zoomFactor } =
    useHookstate(websiteState)

  useEffect(() => {
    if (!cameraControlsRef.current) return
    if (!threeDViewActive.get()) {
      cameraControlsRef.current!.reset(true)
    } else {
      cameraControlsRef.current!.saveState()
      cameraControlsRef.current!.moveTo(1100, 200, 1100, true)
      cameraControlsRef.current!.setTarget(0, 0, 0, true)
    }
  }, [threeDViewActive])

  useEffect(() => {
    cameraControlsRef.current!.zoomTo(zoomFactor.get({ stealth: true }))
  }, [zoomFactor])
  return <></>
}

//TODO write a test for this function
export function setGridValues(
  absolutePosition: { x: number; y: number },
  grid: number[][],
  gridSize: { x: number; y: number },
  cellSize: number,
  brushSize: number,
  brushType: waterdrop_brushType
) {
  const indexOfBrushSizedGrid = absolutePointToCellIndex(
    absolutePosition,
    gridSize,
    brushSize
  )!

  const affectedIndex = indexOfBrushSizedGrid
    ? getListOfAffectedIndexes(
        {
          x: indexOfBrushSizedGrid.i * brushSize + brushSize / 2,
          y: indexOfBrushSizedGrid.j * brushSize + brushSize / 2,
        },
        gridSize,
        cellSize,
        brushSize
      )
    : []

  affectedIndex.forEach((elem) => {
    if (
      elem.i < grid[0].length &&
      elem.j < grid.length &&
      elem.i >= 0 &&
      elem.j >= 0
    ) {
      if (brushType === waterdrop_brushType.add) {
        grid[elem.j][elem.i] = 1
      } else grid[elem.j][elem.i] = 0
    }
  })

  return grid
}

function getEffectiveBrushType(
  altKey: boolean,
  brushType: waterdrop_brushType
) {
  if (!altKey) return brushType
  else if (brushType === waterdrop_brushType.add) return waterdrop_brushType.sub
  else return waterdrop_brushType.add
}

export function getDrawingBoardGestures({
  setCameraControlsActive,
  cameraControlsActive,
  lastMousePosition,
  waterdropState,
  websiteState,
}: {
  setCameraControlsActive: Dispatch<SetStateAction<boolean>>
  cameraControlsActive: boolean
  lastMousePosition: MutableRefObject<
    | {
        x: number
        y: number
      }
    | undefined
  >
  waterdropState: State<typeof waterdropStateObject, {}>
  websiteState: State<typeof websiteStateObject, {}>
}) {
  const { threeDActive, brushType } = websiteState
  const {
    baseGridCellSize,
    waterdropGridSize,
    brushSizeRatio,
    minBrushSize,
    baseGrid,
  } = waterdropState

  const brushSize =
    brushSizeRatio.get({ stealth: true }) * minBrushSize.get({ stealth: true })
  return useGesture(
    {
      onDrag: (state) => {
        const e = state.event

        // console.log(state.first, state.last, state.active)
        if (state.touches > 1) setCameraControlsActive(true)

        //do nothing when the screen is being zoomed, panned or is in 3d view
        if (
          !(
            threeDActive.get({ stealth: true }) ||
            cameraControlsActive ||
            state.touches > 1
          )
        ) {
          const target = e.target as HTMLDivElement
          const bounds = target.getBoundingClientRect()

          const mousePosition = {
            x:
              ((state.xy[0] - bounds.left) * target.offsetWidth) / bounds.width,
            y:
              ((state.xy[1] - bounds.top) * target.offsetHeight) /
              bounds.height,
          }

          let deltaValues = new THREE.Vector2(0, 0)

          if (lastMousePosition.current)
            deltaValues = new THREE.Vector2(
              mousePosition.x - lastMousePosition.current!.x,
              mousePosition.y - lastMousePosition.current!.y
            )

          let deltaDistance = Math.hypot(deltaValues.x, deltaValues.y)
          const deltaUnitVectorIntoCellSize = deltaValues
            .clone()
            .divideScalar(deltaDistance)
            .multiplyScalar(baseGridCellSize.get({ stealth: true }))

          let absolutePosition = new THREE.Vector2(
            mousePosition.x,
            mousePosition.y
          )

          const effectiveBrushType = getEffectiveBrushType(
            state.altKey,
            brushType.get({ stealth: true })
          )

          const updateGridState = (position: { x: number; y: number }) => {
            baseGrid.set((currentState) => {
              const newState = [
                ...setGridValues(
                  position,
                  currentState,
                  waterdropGridSize.get({ stealth: true }),
                  baseGridCellSize.get({ stealth: true }),
                  brushSize,
                  effectiveBrushType
                ),
              ]
              return newState
            })
          }

          while (deltaDistance > baseGridCellSize.get({ stealth: true })) {
            absolutePosition = absolutePosition.sub(deltaUnitVectorIntoCellSize)

            updateGridState(absolutePosition)

            deltaValues = deltaValues.sub(deltaUnitVectorIntoCellSize)
            deltaDistance = Math.hypot(deltaValues.x, deltaValues.y)
          }

          updateGridState(mousePosition)
          lastMousePosition.current = mousePosition
        }
        if (state.last) {
          lastMousePosition.current = undefined
          setCameraControlsActive(false)
        }
      },
    },

    {
      drag: { pointer: { buttons: [1] }, delay: 400 },
    }
  )
}

export default function WaterDropDrawingBoard({
  waterdropState,
  websiteState,
  drawingBoardGestures,
  editorRef,
  cameraControlsRef,
  viewportSize,
}: {
  waterdropState: State<typeof waterdropStateObject, {}>
  websiteState: State<typeof websiteStateObject, {}>
  drawingBoardGestures: (...args: any[]) => any
  editorRef: MutableRefObject<HTMLDivElement | null>
  cameraControlsRef: MutableRefObject<CameraControls | null>
  viewportSize: { height: number; width: number }
}) {
  const { waterdropGridSize } = waterdropState
  const { atLeastOneObjectExists, zoomFactor } = websiteState

  useEffect(() => {
    //644 was experimentally found to be the perfect size for 1 zoom factor
    zoomFactor.set(viewportSize.height / 644)
  }, [viewportSize])

  return (
    <>
      <CameraUpdater cameraControlsRef={cameraControlsRef} />
      <Html
        transform
        occlude={'blending'}
        prepend
        style={{
          width: waterdropGridSize.get().x + 'px',
          height: waterdropGridSize.get().y + 'px',
          outline: '2px dashed',
          outlineColor: 'rgb(229,231,235)',
        }}
        className='bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] bg-[size:11px_11px]'
        distanceFactor={400}
        name='drawingBoard'
      >
        <div
          id='drawing_board'
          className='w-full h-full z-0 flex items-center justify-center '
          {...drawingBoardGestures()}
          ref={editorRef}
        >
          {!atLeastOneObjectExists.get() && (
            <div id='start_drawing'>Start Drawing Here</div>
          )}
        </div>
      </Html>
    </>
  )
}
